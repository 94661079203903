const Colors = {
  BLUE:   '#005AFF',
  GRAY:   '#4D4D4D',
  GREEN:  '#3BB54A',
  PURPLE: '#7A2DBB',
  RED:    '#FF0000',
  ORANGE: '#fc9403',
};
Object.freeze (Colors);
export { Colors };
