import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContext, Auth } from './utils/auth';
import { FirebaseContext, Firebase } from './utils/firebase';
import { DataProvider } from './utils/data';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import { ApolloProvider } from 'react-apollo';
// import { ApolloClient } from 'apollo-client';
// import { createHttpLink } from 'apollo-link-http';
// import { inMemoryCache } from 'apollo-cache-inmemory';

// const httpLink = createHttpLink ({
//   uri: 'http://localhost:4000', // GraphQL Server (TODO)
// });
//
// const client = new ApolloClient ({
//   link: httpLink,
//   cache: new InMemoryCache (),
// });

// ReactDOM.render (
//   <ApolloProvider client={client}>
//     <App />
//   </ApolloProvider>,
//   document.getElementById('root')
// );

ReactDOM.render (
  <FirebaseContext.Provider value={new Firebase ()}>
    <AuthContext.Provider value={new Auth ()}>
      <DataProvider>
        <Router>
          <App />
        </Router>
      </DataProvider>
    </AuthContext.Provider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
