import React, { Component } from 'react';
import '../styles/App.css';
import './Input.css';

let icon_valid = require('../resources/icons/check-green.png');
let icon_error = require('../resources/icons/x-red.png');

// Props
// -----------------------------------------------------------
// autocomplete (on, off)
// autofocus
// description [string]: Text description to be placed below the box.
// disabled
// error [string]: Text error message to be placed below the box (replacing description) in the error state. Overrides default validation error messages.
// form [string]
// max [number, date]
// maxlength [number]
// min [number, date]
// * name [string]: String for label and placeholder values
// pattern [regex]
// placeholder [string]
// readonly
// required
// size [number]
// step [number]
// type (date, datetime-local, email, month, number, password, search, tel, text, time, url, week)
// value
// -----------------------------------------------------------



// TODO:
// -----------------------------------------------------------
// - Reorganize styles by state name ("focus", etc.) ?
// - Convert icons to vector format
// - Support all props
// -----------------------------------------------------------

const style_smallLabel = {
  fontSize: '0.8rem',
  position: 'absolute',
  padding: '0 4px 0 4px',
  margin: '0 4px 0 6px',
  borderRadius: '5px',
  backgroundColor: '#ffffff',
  height: '20px',
  bottom: '31px',
  display: 'flex',
  alignItems: 'flex-end',
};

const styles = {
  container: {
    focus: {
      border: '2px solid #3B88FD',
    },
    valid: {
      border: '2px solid #3bb54a',
    },
    error: {
      border: '2px solid #FF0000',
    },
    disabled: {
      border: '2px solid #BABABA',
    },
  },
  label: {
    focus: {
      ...style_smallLabel,
      color: '#3B88FD',
    },
    valid : {
      ...style_smallLabel,
      color: '#3bb54a',
    },
    error: {
      ...style_smallLabel,
      color: '#FF0000',
    },
    disabled: {
      color: '#CCCCCC',
    },
  },
  input: {
    focus: {
      outline: 'none',
    },
  },
  icon : {
    valid: {
      display: 'flex',
      height: '30px',
      width: '30px',
    },
    error: {
      display: 'flex',
      height: '30px',
      width: '30px',
    },
  }
};

class Input extends Component {
  state = {
    value: this.props.value || '',
    hover: false,
    focus: false,
    valid: false,
    error: false,
    disabled: this.props.disabled || false,

    icon: null,

    style: {
      container: (this.props.disabled) ? {...styles.container.disabled} : null,
      label: (this.props.disabled) ? {...styles.label.disabled} : null,
      input: null,
      icon: null,
    },
  };

  // componentDidMount () {
  //   this.checkAutofill ();
  // }

  validate = () => {
    // TODO

    return true;
  };

  setStyle = (style) => {
    this.setState ({ style: style });
  };

  setInputState = (inputState, value = true) => {
    // Options: hover, focus, valid, error, disabled, default
    switch (inputState) {
      case ('hover'):
        this.setState ({ hover: value });
        return;
      case ('focus'):
        this.setState ({ focus: value });
        this.setStyle ({
          container: value ? {...styles.container.focus} : null,
          label: value ? {...styles.label.focus} : null,
          input: value ? {...styles.input.focus} : null,
        });
        return;
      case ('valid'):
        this.setState ({ valid: value, icon: icon_valid });
        this.setStyle ({
          container: {...styles.container.valid},
          label: {...styles.label.valid},
          input: null,
          icon: {...styles.icon.valid},
        });
        return;
      case ('error'):
        this.setState ({ error: value, icon: icon_error });
        this.setStyle ({
          container: {...styles.container.error},
          label: {...styles.label.error},
          input: null,
          icon: {...styles.icon.error},
        });
        return;
      default:
        this.setState ({ icon: null });
        this.setStyle ({
          container: null,
          input: null,
          icon: null,
        });
    }
  };

  setInputRef = (elm) => {
    this.ref_input = elm;
  };

  // TODO: Figure out how to coopt this issue in Chrome.
  // checkAutofill = () => {
  //   // Chrome's autofill doesn't make it easy to get values or even detect if it
  //   // fired for a given input. This is a hack that checks for a psuedoclass
  //   // Chrome adds to autofilled fields. We need to do this to properly set the
  //   // input state, avoid visual ugliness, and auto perform validation.
  //   // https://www.bennadel.com/blog/3476-checking-to-see-if-an-element-has-a-css-pseudo-class-in-javascript.htm
  //   let input = this.ref_input;
  //   let selector = ':-internal-autofill-selected';
  //   // Most modern browsers support the ".matches" method. However, IE9+ uses a
	// 	// non-standard method name. As such, we can fall-back to the IE version when
	// 	// the standard one doesn't exist and that should cover all the modern
	// 	// browsers that are in use.
  //   // https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
  //   let nativeMatches = (input.matches || input.msMatchesSelector);
  //   // CAUTION: If an invalid pseudo-selector is used in Firefox or IE, the
	// 	// browser will throw a SyntaxError, "is not a valid selector". It will do
	// 	// the same for .querySelector() as well.
  //   try {
  //     let autofilled = nativeMatches.call (input, selector);
  //     if (autofilled) {
  //       console.log ("autofilled");
  //     } else {
  //       console.log ("nope");
  //     }
  //   } catch (error) {
  //     console.log ("error");
  //     // Do nothing.
  //   }
  // };

  onChange = (e) => {
    const value = e.target.value;
    this.setState ({ value: value }); // TODO: Remove now that we lift state up?

    this.props.onChange (e); // CURRENT
  };

  onMouseEnter = (e) => {
    this.setInputState ('hover');
  };

  onMouseLeave = (e) => {
    this.setInputState ('hover', false);
  };

  onFocus = (e) => {
    this.setInputState ('focus');
  };

  onBlur = (e) => {
    this.setInputState ('focus', false);
    if (this.state.value) {
      if (this.validate ()) {
        this.setInputState ('valid');
      } else {
        this.setInputState ('error');
      }
    } else {
      this.setInputState ('default'); // empty
    }
  };

  onClick = (e) => {
    // When any element with <label> is single clicked, make sure nothing is selected.
    let elm = this.ref_input;
    if (!elm || !elm.selectionEnd) { return; }
    elm.selectionStart = elm.selectionEnd;
  }

  onDoubleClick = (e) => {
    // When any element within <label> is double clicked, select all the text in the input field.
    if (this.state.value) {
      this.ref_input.select ();
    }
  };

  render () {
    let inputType = this.props.type || "text";
    let label = this.props.name;
    let hasValue = this.state.value;
    let placeholder = (hasValue) ? null : this.props.placeholder || null;
    let icon = this.state.icon;
    let disabled = this.state.disabled;

    let description = this.props.description || null;  // TODO
    let error = this.props.error || null;              // TODO

    // TODO: Other supported props

    return (
      <div className="input-wrapper">
        <div className="input-container" style={this.state.style.container} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <label onClick={this.onClick} onDoubleClick={this.onDoubleClick}>
            <div className="input-label" style={this.state.style.label}>{label}</div>
            <input {...this.props} disabled={disabled} type={inputType} style={this.state.style.input} placeholder={placeholder} name={label} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} ref={this.setInputRef} />
            <div className="input-icon-container">
              <img src={icon} className="input-icon" style={this.state.style.icon} alt="" />
            </div>
          </label>
        </div>
      </div>
    );
  }
}

// value={this.state.value}

export default Input;
