import React, { Component } from 'react';
import '../../../styles/App.css';


class IconNotifications extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title>notifications</title>

        <path style={{fill: color}} d="M375.7812,0h48.4376c.8071,1.2682,2.1039.7271,3.1911.8036A395.9231,395.9231,0,0,1,527.7178,20.731c68.363,23.2821,126.573,61.9666,173.9,116.5174q85.3437,98.3694,96.9872,228.3437c.3052,3.3924-.2573,6.9619,1.3947,10.1891V425c-1.4049.9095-.7745,2.3744-.8624,3.5665a396.2856,396.2856,0,0,1-19.7271,98.7765c-23.2783,68.501-61.99,126.8531-116.6532,174.272q-98.3769,85.34-228.3491,96.99c-3.3925.3053-6.9621-.2572-10.1894,1.3947H375c-.91-1.4047-2.3743-.7741-3.5666-.8626-33.8733-2.5153-67.0211-8.813-99.1379-19.8813q-131.089-45.177-207.54-160.9763C28.05,562.4542,7.1678,500.99,1.3983,434.3953,1.1272,431.2656,1.6014,427.9565,0,425V375c1.4048-.91.7747-2.3745.8625-3.5668A396.0994,396.0994,0,0,1,20.59,272.6554C43.8684,204.1544,82.58,145.8019,137.244,98.3835Q235.6215,13.0456,365.5917,1.3948C368.9842,1.09,372.5539,1.6519,375.7812,0ZM631.1251,572.905c0-1.8048,0-3.2111,0-4.6173.0022-17.3155-.3271-34.64.136-51.9429.2611-9.7575-3.3814-17.108-10.6391-23.1-9.3633-7.7311-20.1956-12.7667-31.481-16.8693-2.9907-1.0872-4.0878-2.4514-4.0728-5.7679.1468-32.2868.0527-64.5747.1119-96.8621a185.63,185.63,0,0,0-1.5112-25.6956c-5.883-42.7845-24.891-78.7793-56.3558-108.1711a180.6336,180.6336,0,0,0-75.9017-42.4315c-2.2743-.6336-3.0935-1.6444-3.03-4.0286a139.1463,139.1463,0,0,0-.1148-16.393c-2.1452-23.3416-22.1855-41.6416-46.3563-42.6146-24.2338-.9756-45.63,15.9543-49.7055,39.2221-1.113,6.3534-.5471,12.7281-.57,19.09-.01,2.7279-.6234,4.1239-3.4976,4.8757a152.0375,152.0375,0,0,0-35.4439,14.0863c-60.0222,33.3316-93.9955,83.8737-97.5187,152.7871-1.8124,35.45-.3568,71.0684-.2722,106.61.0065,2.7278-.7269,4.0224-3.4031,5.0178-11.9813,4.4562-23.6348,9.6177-33.3485,18.24-5.6724,5.0354-9.3928,11.0606-9.368,18.9607.0575,18.3566.0564,36.7133.108,55.07.0039,1.3947-.3274,2.8507.5991,4.5343ZM472.8675,594.7281H327.5991c-1.1194,18.99,9.2479,45.524,33.7423,59.9023,25.9071,15.2076,52.3931,15.2589,77.9747-.4937C460.9971,640.7859,471.8057,620.5724,472.8675,594.7281Z"/>
        <path style={{fill: '#FFFFFF'}} d="M631.1251,572.905H169.49c-.9265-1.6836-.5952-3.14-.5991-4.5343-.0516-18.3566-.05-36.7133-.108-55.07-.0248-7.9,3.6956-13.9253,9.368-18.9607,9.7137-8.6228,21.3672-13.7843,33.3485-18.24,2.6762-.9954,3.41-2.29,3.4031-5.0178-.0846-35.542-1.54-71.1605.2722-106.61,3.5232-68.9134,37.4965-119.4555,97.5187-152.7871a152.0375,152.0375,0,0,1,35.4439-14.0863c2.8742-.7518,3.4878-2.1478,3.4976-4.8757.0228-6.3615-.5431-12.7362.57-19.09,4.076-23.2678,25.4717-40.1977,49.7055-39.2221,24.1708.973,44.2111,19.273,46.3563,42.6146a139.1463,139.1463,0,0,1,.1148,16.393c-.0636,2.3842.7556,3.395,3.03,4.0286a180.6336,180.6336,0,0,1,75.9017,42.4315c31.4648,29.3918,50.4728,65.3866,56.3558,108.1711A185.63,185.63,0,0,1,585.18,373.745c-.0592,32.2874.0349,64.5753-.1119,96.8621-.015,3.3165,1.0821,4.6807,4.0728,5.7679,11.2854,4.1026,22.1177,9.1382,31.481,16.8693,7.2577,5.9925,10.9,13.343,10.6391,23.1-.4631,17.3031-.1338,34.6274-.136,51.9429C631.1249,569.6939,631.1251,571.1,631.1251,572.905Z"/>
        <path style={{fill: '#FFFFFF'}} d="M472.8675,594.7281c-1.0618,25.8443-11.87,46.0578-33.5514,59.4086-25.5816,15.7526-52.0676,15.7013-77.9747.4937-24.4944-14.3783-34.8617-40.9127-33.7423-59.9023Z"/>

      </svg>

    );

  }
}

export default IconNotifications;
