import React, { useState, useEffect, useContext } from 'react';
// import rd3 from 'react-d3-library'
import * as d3 from 'd3';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { FirebaseContext } from '../../utils/firebase';

import { getLocalePrintFormat, getBaseDate, compareBaseDates } from '../../utils/utils';
import '../../styles/App.css';
import '../Module.css';
import './InventoryReport.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Select from '../../components/Select';
import ShallowLink from '../../components/ShallowLink';


// const RD3Component = rd3.Component;

const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];


function InventoryChart (props) {
  let { data, width, height } = props;

  useEffect(() => {
    if (data) {
      drawChart();
    }
  }, [data]);

  function drawChart() {

    d3.select('#inventory-chart-container')
      .select('svg')
      .remove();

    d3.select('#inventory-chart-container')
      .select('.inventory-report-tooltip')
      .remove();

    if (!data) {
      return null;
    }

    const margin = { top: 20, right: 20, bottom: 20, left: 20 };
    const yMinValue = d3.min(data, d => d.value);
    const yMaxValue = d3.max(data, d => d.value);
    const xMinValue = d3.min(data, d => d.label);
    const xMaxValue = d3.max(data, d => d.label);

    const svg = d3
      .select('#inventory-chart-container')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const tooltip = d3
      .select('#inventory-chart-container')
      .append('div')
      .attr('class', 'inventory-report-tooltip')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .style('display', 'none');

    const xScale = d3
      .scaleTime()
      .domain (d3.extent (data, d => d.label))
      .range ([0, width]);

    const yScale = d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, yMaxValue])
      .nice ();

    const line = d3
      .line()
      .x(d => xScale(d.label))
      .y(d => yScale(d.value))
      .curve(d3.curveMonotoneX);

    // Grid
    // svg
    //   .append('g')
    //   .attr('class', 'grid')
    //   .attr('transform', `translate(0,${height})`)
    //   .call(
    //   d3.axisBottom(xScale)
    //       .tickSize(-height)
    //       .tickFormat(''),
    //   );
    //
    // svg
    //   .append('g')
    //   .attr('class', 'grid')
    //   .call(
    //       d3.axisLeft(yScale)
    //       .tickSize(-width)
    //       .tickFormat(''),
    //   );

    // X Axis
    svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height})`)
      // .call(d3.axisBottom().scale(xScale).tickSize(15));
      .call(d3.axisBottom(xScale)
        .ticks(d3.timeWeek.every(1)));

    // Y Axis
    svg
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScale));

    // Data Line
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#3bb54a')
      .attr('stroke-width', 4)
      .attr('class', 'line')
      .attr('d', line);

    // Tooltip
    const focus = svg
      .append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    focus
      .append('circle').attr('r', 5).attr('class', 'circle');

    let tooltipDate = tooltip.append ("div");
    tooltipDate.append ("span")
      .attr("class", "inventory-report-tooltip-title")
      .text("Week Ending: ");

    let tooltipxValue = tooltip.append ("span")
      .attr ("class", "inventory-report-tooltip-xValue");

    let tooltipBars = tooltip.append ("div");
    tooltipBars.append ("span")
      .attr("class", "inventory-report-tooltip-title")
      .text("Bars / Hour: ");

    var tooltipBarsValue = tooltipBars.append("span")
      .attr("class", "inventory-report-tooltip-yValue");

    svg
      .append('rect')
      .attr('class', 'inventory-report-overlay')
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', () => {
        focus.style('display', null);
        tooltip.style ("display", null);
        // tooltip
        //   .transition()
        //   .duration(100)
        //   .style('opacity', 0.9);
      })
      .on('mouseout', () => {
        focus.style('display', 'none');
        tooltip.style ("display", 'none');
        tooltip
          .transition()
          .duration(100)
          .style('opacity', 0);
      })
     .on('mousemove', mousemove);

    function mousemove (event) {

      if (!data) { return; }

      let bisectDate = d3.bisector(function(d) { return d.label; }).left;
      //let formatValue = d3.format(",");
      let dateFormatter = d3.timeFormat("%m/%d/%y");

      var x0 = xScale.invert (d3.pointer (event) [0]);
      var i = bisectDate (data, x0, 1);
      var d0 = data [i-1];
      var d1 = data [i];
      if (!d0 || !d1) { return; }
      var d = x0 - d0.label > d1.label - x0 ? d1 : d0;

      // console.log (d3.pointer (event) [0]);
      // console.log (x0);
      // console.log (i);
      // console.log (d0);
      // console.log (d1);

      let xPos = event.clientX + 25 + 'px';
      let yPos = event.clientY + 13 + 'px';

      focus.attr("transform", "translate(" + xScale(d.label) + "," + yScale(d.value) + ")");
      // tooltip.attr("style", "left:" + (xScale(d.label) + 64) + "px;top:" + yScale(d.value) + "px;");
      tooltip.attr("style", `left: ${xPos}; top: ${yPos}`);
      tooltip.select(".inventory-report-tooltip-xValue").text (dateFormatter(d.label));
      tooltip.select(".inventory-report-tooltip-yValue").text (Number.parseFloat (d.value).toFixed (3));

    }

  }
  return <div id="inventory-chart-container" />;
}


export default function InventoryReport (props) {

  const appState = useContext (DataContext);
  const firebase = useContext (FirebaseContext);

  const [ loading, setLoading ] = useState (true);
  const [ activeHub, setActiveHub ] = useState (null);
  const [ hubs, setHubs ] = useState (null);
  const [ inventoryStats, setInventoryStats ] = useState (null);
  const [ weeklyStats, setWeeklyStats ] = useState (null);
  const [ weeklyStatsChart, setWeeklyStatsChart ] = useState (null);


  useEffect (() => {

    async function fetchHubs () {
      let _hubs = [];
      var db = firebase.firebase.firestore ();
      let snapshot = await db.collection ('hubs').get ();
      for (let doc of snapshot.docs) {
        let row = doc.data ();
        row.id = doc.id;
        row.name = `${row.name}, ${row.country}`
        _hubs.push (row);
        console.log (row);
      }
      setHubs (_hubs);
      if (_hubs.length) {
        setActiveHub (_hubs [0].id);
      }
    }
    fetchHubs ();

  }, []);


  useEffect (() => {

    async function fetchProductionData () {

      if (!activeHub) {
        setInventoryStats (null);
        setWeeklyStats (null);
        return;
      }

      var db = firebase.firebase.firestore ();

      let weeklyStats = [];

      // TODO: Paginate?
      console.log (activeHub);
      let snapshot = await db.collection ('hubs').doc (activeHub).collection ('weeklyProduction').orderBy ("endDate").get ();
      let lastWeekStats = {};
      for (let doc of snapshot.docs) {
        let row = doc.data ();
        let dateParts = row.endDate.split ('-');
        if (!dateParts || dateParts.length != 3) {
          continue;
        }
        let date = new Date (dateParts [0], dateParts [1]-1, dateParts [2]);
        let displayDate = `${dateParts [2]}-${months [dateParts [1] -1]}-${dateParts [0].substring (2)}`;
        const barsPerWorkerHour = row.bars / (row.soapmakerHours + row.supportHours);
        weeklyStats.push ({
          label: date,
          value: barsPerWorkerHour,
          tooltipContent: `<b>x: </b>${displayDate}<br><b>y: </b>${barsPerWorkerHour}`,
        });
        lastWeekStats.startDate = row.startDate;
        lastWeekStats.endDate = row.endDate;
        lastWeekStats.barsFinished = parseInt (row.bars) || 0;
        lastWeekStats.soapmakerHours = row.soapmakerHours || 0;
        lastWeekStats.supportHours = row.supportHours || 0;
        lastWeekStats.totalWorkerHours = lastWeekStats.soapmakerHours + lastWeekStats.supportHours;
        lastWeekStats.avgBarsPerWorkerHour = Number.parseFloat (lastWeekStats.barsFinished / lastWeekStats.totalWorkerHours).toFixed (3);
      }
      setInventoryStats (lastWeekStats);
      setWeeklyStats (weeklyStats);
      setLoading (false);
    }
    fetchProductionData ();
  }, [activeHub]);


  // If the weekly stats change, update the chart.
  // useEffect (() => {
  //
  // }, [weeklyStats]);


  // If the active hub changes, update this module with the new data.
  useEffect (() => {
    const state = appState.state;
    if (state.activeHub) {
      setActiveHub (state.activeHub);
      setLoading (false);
    }
  }, [appState.state.activeHub]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const getTableSection = (stats) => {
    return (
      <div className="inventory-report-table-section">
        {
          stats.map (stat => {
            return (
              <div key={stat.header} className="inventory-report-table-row">
                <p className="inventory-report-header">{stat.header}</p>
                <p className="inventory-report-stat">{stat.value}</p>
                <p className="inventory-report-units">{stat.unit}</p>
              </div>
            );
          })
      }
      </div>
    );
  };

  const getInventoryStats = () => {
    // if (!activeHub || !activeHub.impact) { return null; }
    if (!inventoryStats) { return null; }

    const stats = [];

    // Worker Hours
    const workerHours = [];
    if (inventoryStats.soapmakerHours) {
      workerHours.push ({
        header: "Soapmaker Hours:",
        value: inventoryStats.soapmakerHours,
        unit: 'hours',
      });
    }
    if (inventoryStats.supportHours) {
      workerHours.push ({
        header: "Support Hours:",
        value: inventoryStats.supportHours,
        unit: 'hours',
      });
    }
    if (inventoryStats.totalWorkerHours) {
      workerHours.push ({
        header: "Total Worker Hours:",
        value: inventoryStats.totalWorkerHours,
        unit: 'hours',
      });
    }

    // Bars Finished
    const barsFinished = [];
    if (inventoryStats.barsFinished) {
      barsFinished.push ({
        header: "Bars Finished:",
        value: inventoryStats.barsFinished,
        unit: 'bars',
      });
    }
    if (inventoryStats.avgBarsPerWorkerHour) {
      barsFinished.push ({
        header: "Feedstock:",
        value: inventoryStats.avgBarsPerWorkerHour,
        unit: 'bars equivalent',
      });
    }

    // Shipping
    const shipping = [];
    if (inventoryStats.barsShipped) {
      shipping.push ({
        header: "Bars Shipped:",
        value: inventoryStats.barsShipped,
        unit: 'bars',
      });
    }
    if (inventoryStats.feedstockInInventory) {
      shipping.push ({
        header: "Feedstock In Inventory:",
        value: inventoryStats.feedstockInInventory,
        unit: 'bars',
      });
    }

    return (
      <div className="inventory-report-table">
        { getTableSection (workerHours) }
        <div className="inventory-report-table-row"></div>
        { getTableSection (barsFinished) }
        <div className="inventory-report-table-row"></div>
        { getTableSection (shipping) }
      </div>
    );
  };

  const getInventoryChart = () => {
    // if (!activeHub || !activeHub.mediaThumb) { return null; }
    // return ( <div className="inventory-report-chart-img"></div>) // TODO
    // var node = document.createElement ('div');
    // return <RD3Component data={weeklyStats} />
    if (!weeklyStats) {
      return '[ No data ]';
    }
    return <InventoryChart data={weeklyStats} width={300} height={200} />;
  };

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        <div className="inventory-report">
          <div className="inventory-report-stats">
            {getName ()}
            <div className="inventory-report-select-hub">
              <p>Hub:</p>
              <Select options={hubs} action={setActiveHub} /></div>
              <p className="inventory-report-week"><strong>Week Ending:</strong>{(inventoryStats || {}).endDate}</p>
              { getInventoryStats () }
          </div>
          <div className="inventory-report-chart">
            <h3>Feedstock and Bar Inventory</h3>
            {/* <div className="inventory-report-chart-img"></div> */}
            { getInventoryChart () }
          </div>
        </div>
      </div>
    </div>
  );

}
