import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from "react-router-dom";
import { AuthContext } from '../utils/auth';
import { Navigation } from '../utils/navigation';
import '../styles/App.css';
import '../styles/Login.css';

import Input from '../components/Input';
import Button from '../components/Button';
import ShallowLink from '../components/ShallowLink';

import logo from '../resources/img/logo.png';


function Login (props) {

  const auth = useContext (AuthContext);

  // State
  const [form, setForm] = useState ({});

  useEffect (() => {
    console.log ('Login: Setting loading to false');
    props.setLoading (false);
  }, []);

  const onChangeValue = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    form [name] = value;
    setForm (form);
  };

  const onSubmit = async () => {
    // Attempt to log in the user with email and password.
    let email = form.Email;
    let password = form.Password;
    const success = await auth.signInWithEmailAndPassword (email, password);
    if (success) {
      console.log ('Login: Success');
    }
  };

  const onClickForgot = (e) => {
    auth.resetPassword (form.Email);
    alert (`Please check your email to reset your password.`);
  };

  // TODO: 3PA
  // ----------------------------------
  // {GoogleAuthButton}
  // {FBAuthButton}
  // <div className="login-or">or</div>
  // ----------------------------------

  return (

      <div className="fullscreen">
        <div className="card-main">
          <img src={logo} className="logo-esb" alt="Eco-Soap Bank Logo" />
          <h1 className="login-h1">Portal Login</h1>
          <Input name="Email" required value={form.Email} onChange={onChangeValue}></Input>
          <Input name="Password" required type="password" value={form.Password} onChange={onChangeValue}></Input>
          <Button onClick={onSubmit}>Log In</Button>
          <ShallowLink onClick={onClickForgot}>Forgot?</ShallowLink>
        </div>
      </div>

  );

}

export default Login;
