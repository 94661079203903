import React, { Component, useContext } from 'react';
import { Link } from 'react-router-dom';
import '../styles/App.css';
import './ShallowLink.css';

import IconEdit from '../resources/icons/svg/IconEdit';
import IconProfile from '../resources/icons/svg/IconProfile';
// import IconExport from '../resources/icons/export-active.png';  // TODO: Convert to SVG

// Props
// -----------------------------------------------------------
// color
// -----------------------------------------------------------


// TODO:
// -----------------------------------------------------------
// -
// -----------------------------------------------------------

const styles = {
  text: {
    hover: {

    },
    focus: {

    },
    disabled: {
      color: '#B2B2B2',
    },
    green: {
      color: '#3bb54a',
    },
    blue: {
      color: '#3B88FD',
    },
    gray: {
      color: '#4D4D4D',
    },
    red: {
      color: '#FF0000',
    }
  },
};

class ShallowLink extends Component {
  state = {
    text: this.props.children || '',
    hover: false,
    focus: false,
    disabled: this.props.disabled || false,

    style: {
      text: (this.props.disabled) ? {...styles.text.disabled} : null,
    },
  };

  componentDidMount () {
    this.setStyle ({text: this.getTextStyle ()});
  }

  componentDidUpdate (prevProps) {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState ({
        disabled: this.props.disabled,
      });
      this.setStyle ({text: this.getTextStyle ()});
    }
  }

  getTextStyle = () => {
    let textStyle = {};
    if (this.props.color && styles.text [this.props.color]) {
      textStyle = { ...textStyle, ...styles.text [this.props.color]};
    }
    if (this.props.fontSize) {
      textStyle = { ...textStyle, fontSize: this.props.fontSize };
    }
    if (this.props.bold) {
      textStyle = { ...textStyle, fontWeight: 600 };
    }
    if (this.props.disabled) {
      textStyle = { ...textStyle, ...styles.text.disabled };
    }

    return textStyle;
  };



  getIcon = () => {
    let color = this.props.color || null;
    let size = this.props.iconSize || this.props.fontSize || '1rem';
    switch (this.props.icon) {
      case 'edit':    return <IconEdit color={color} height={size} width={size} />;
      case 'profile': return <IconProfile color={color} height={size} width={size} />;





      default:      return null;
    }
  };

  validate = () => {
    // TODO

    return true;
  };

  setStyle = (style) => {
    this.setState ({ style: style });
  };

  setButtonState = (buttonState, value = true) => {
    // Options: hover, focus, disabled, default
    switch (buttonState) {
      case ('hover'):
        this.setState ({ hover: value });
        return;
      case ('focus'):
        this.setState ({ focus: value });
        this.setStyle ({
          text: value ? {...styles.text.focus} : null,
        });
        return;
      case ('disabled'):
        this.setState ({ disabled: value });
        this.setStyle ({
          text: {...styles.text.disabled},
        });
        return;
      default:
        this.setState ({ icon: null });
        this.setStyle ({
          text: null,
        });
    }
  };

  onMouseEnter = (e) => {
    this.setInputState ('hover');
  };

  onMouseLeave = (e) => {
    this.setInputState ('hover', false);
  };

  onFocus = (e) => {
    this.setInputState ('focus');
  };

  onBlur = (e) => {
    this.setInputState ('focus', false);
  };

  // onClick = (screen, params, route) => {
  //   if (!this.state.disabled) {
  //     let path = this.props.to;
  //     if (path) {
  //       console.log (screen);
  //       console.log (params);
  //       console.log (route);
  //       route (path, params);
  //     }
  //   }
  // }

  // getNavigationContext = () => {
  //   const navigation = useContext (NavigationContext);
  //   console.log (navigation);
  // };

  getLink = (text, disabled) => {
    if (!disabled) {
      return (
        <Link className="link-text" style={this.state.style.text} to={this.props.to}>
          {text}
        </Link>
      );
    } else {
      return (
        <Link className="link-text" style={this.state.style.text}>
          {text}
        </Link>
      );
    }
  };

  render () {
    let text = this.state.text;
    let disabled = this.state.disabled;

    return (
      <div className="link-wrapper" onClick={(e) => this.props.onClick (e)}>
        {this.getIcon ()}
        {this.getLink (text, disabled)}
      </div>
    );
  }
}

export default ShallowLink;
