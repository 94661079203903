import React from 'react';
import { Route, Redirect } from "react-router-dom";

// Screens
// =============================================================================
import Login from '../screens/Login';
// import Dashboard from '../screens/Dashboard';
// import Profile from '../screens/Profile';
// import Payment from '../screens/Payment';
// import Pickups from '../screens/Pickups';
// import Properties from '../screens/Properties';
import Register from '../screens/Register';
import Reporting from '../screens/Reporting';
import Support from '../screens/Support';
// import Users from '../screens/Users';
import Verify from '../screens/Verify';

// Icons
// =============================================================================
import IconDashboard from '../resources/icons/svg/IconDashboard';
import IconProfile from '../resources/icons/svg/IconProfile';
import IconPayment from '../resources/icons/svg/IconPayment';
import IconPickups from '../resources/icons/svg/IconPickups';
import IconProperties from '../resources/icons/svg/IconProperties';
import IconNotifications from '../resources/icons/svg/IconNotifications';
import IconUsers from '../resources/icons/svg/IconUsers';
//import IconDatabase from '../resources/icons/svg/IconDatabase';
// import IconWiki from '../resources/icons/svg/IconWiki';
// import IconDirectory from '../resources/icons/svg/IconDirectory';
import IconSupport from '../resources/icons/svg/IconSupport';
import IconLogOut from '../resources/icons/svg/IconLogOut';


export class CancelablePromise {

  constructor (executor, context) {
    let promise = new Promise (executor);
    return this.makeCancelable (promise, context);
  }

  // Make promises cancelable so they can be cancelled in componentWillUnmount
  // to prevent memory leaks upon navigating away from a component with a
  // pending promise.
  // - https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
  // - https://github.com/facebook/react/issues/5465#issuecomment-157888325
  makeCancelable = (promise, context) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
        error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
      );
    });

    let wrapper = {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true;
      },
    };

    // Push the cancelable promise to an array in the component state.
    // Then we can cancel all pending promises in componentWillUnmount.
    let pending = context.state.pendingPromises;
    pending.push (wrapper);
    context.setState ({ pendingPromises: pending });

    return wrapper;
  };

  static cleanUp = (context) => {
    for (let pending of context.state.pendingPromises) {
      pending.cancel ();
    }
  }

}

const screens = {
  dashboard: {
    name: 'Dashboard',
    code: 'dashboard',
    path: '/dashboard',
    // component: Dashboard,
    icon: IconDashboard, // TODO
    showSidebar: true,
    public: false,
  },
  database: {
    name: 'Database',
    code: 'database',
    path: '/database',
    // component: Dashboard,
    icon: IconDashboard,
    showSidebar: true,
    public: false,
  },
  login: {
    name: 'Login',
    code: 'login',
    path: '/login',
    component: Login,
    showSidebar: false,
    public: true,
  },
  payment: {
    name: 'Payment',
    code: 'payment',
    path: '/payment',
    // component: Payment,
    icon: IconPayment,
    showSidebar: true,
    public: false,
  },
  pickups: {
    name: 'Pickups',
    code: 'pickups',
    path: '/pickups',
    // component: Pickups,
    icon: IconPickups,
    showSidebar: true,
    public: false,
  },
  profile: {
    name: 'Profile',
    code: 'profile',
    path: '/profile',
    // component: Profile,
    icon: IconProfile,
    showSidebar: true,
    public: false,
  },
  properties: {
    name: 'Properties',
    code: 'properties',
    path: '/properties',
    // component: Properties,
    icon: IconProperties,
    showSidebar: true,
    public: false,
  },
  register: {
    name: 'Register',
    code: 'register',
    path: '/register',
    component: Register,
    showSidebar: false,
    public: true,
  },
  reporting: {
    name: 'Reporting',
    code: 'reporting',
    path: '/reporting',
    component: Reporting,
    showSidebar: false,
    public: false,
  },
  support: {
    name: 'Support',
    code: 'support',
    path: '/support',
    component: Support,
    icon: IconSupport,
    showSidebar: true,
    public: false,
  },
  users: {
    name: "Users",
    code: 'users',
    path: '/users',
    // component: Users,
    icon: IconUsers,
    showSidebar: true,
    public: false,
  },
  verify: {
    name: 'Verify',
    code: 'verify',
    path: 'verify',
    component: Verify,
    showSidebar: false,
    public: true,
  },
  // wiki: {
  //   name: 'Wiki',
  //   code: 'wiki',
  //   path: 'wiki',
  //   showSidebar: true,
  //   public: false,
  // },
  // {
  //   path: undefined,
  //   name: 'Dashboard',
  //   code: 'dashboard'
  //   component: Dashboard, // TODO: Error404 component
  //   icon: IconDashboard,
  //   showSidebar: true,
  // }
};

// static defaultScreen = Login;
const defaultScreen = screens.dashboard;  // TEMP
// const defaultScreen = screens.reporting;  // TEMP

const paths = {
  '/': defaultScreen,
  '/dashboard': screens.dashboard,
  '/database': screens.database,
  '/login': screens.login,
  '/payment': screens.payment,
  '/pickups': screens.pickups,
  '/profile': screens.profile,
  '/properties': screens.properties,
  '/register': screens.register,
  '/reporting': screens.reporting,
  '/support': screens.support,
  '/users': screens.users,
  '/verify': screens.verify,
  // '/wiki': screens.wiki,
};

export class Navigation {

  static get screens ()       { return screens; }
  static get defaultScreen () { return defaultScreen; }
  static get paths ()         { return paths; }

  static rememberPath (path) {
    this.requestedPath = path;
    return path;
  }

  static restorePath () {
    console.log (`Restoring path ${this.requestedPath}`);
    let pathToRestore = this.requestedPath;
    this.requestedPath = undefined;
    if (pathToRestore === '/login') {
      console.log ('THE PATH TO RESTORE WAS LOGIN');
    }
    return pathToRestore;
  }

  static forgetPath () {
    console.log ('Forgetting path.');
    this.requestedPath = undefined;
  }

  static setPath (path) {
    console.log (`Setting current path to ${path}`);
    this.path = path;
  }

  static getPath () {
    return this.path;
  }

  static cleanUp = CancelablePromise.cleanUp;

  static set = (path) => {

  };

};
