import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { FirebaseContext } from '../../utils/firebase';
import { Role, Roles } from '../../utils/roles';
import { DataContext, Actions } from '../../utils/data';
import { getLocalePrintFormat, getBaseDate, compareBaseDates } from '../../utils/utils';
import DatePicker from "react-datepicker";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import '../../styles/App.css';
import '../Module.css';
import './ProductionData.css';
import "react-datepicker/dist/react-datepicker.css";

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Select from '../../components/Select';
import ShallowLink from '../../components/ShallowLink';
import DatabaseView from '../../components/DatabaseView';

import IconExport from '../../resources/icons/export-active.png';


// const hub = 'Siem Reap';

export default function ProductionData (props) {

  const appState = useContext (DataContext);
  const auth = useContext (AuthContext);
  const firebase = useContext (FirebaseContext);

  const [ loading, setLoading ] = useState (true);
  const [ activeHub, setActiveHub ] = useState (null);
  const [ hubs, setHubs ] = useState (null);
  const [ productionData, setProductionData ] = useState (null);

  useEffect (() => {

    async function fetchHubs () {

      let _hubs = [];
      var db = firebase.firebase.firestore ();

      // - If user role is ADMIN, they can select among all Hubs.
      // - If user role is HUB_ADMIN, they only see the data from their hub
      //   This info lives in /users/{id}/hub (which is a reference to
      //   /hubs/{hubId}).

      const role = auth.getRole ();
      let snapshot;
      switch (role) {
        case (Role.ADMIN):
          snapshot = await db.collection ('hubs').get ();
          for (let doc of snapshot.docs) {
            let row = doc.data ();
            row.id = doc.id;
            row.name = `${row.name}, ${row.country}`
            _hubs.push (row);
          }
          break;

        case (Role.HUB_ADMIN || Role.HUB_USER):
          const hubId = await auth.getUserHubId ();
          snapshot = await db.collection ('hubs').doc (hubId).get ();
          let row = snapshot.data ();
          row.id = hubId;
          row.name = `${row.name}, ${row.country}`;
          _hubs.push (row);
          break;
      }

      setHubs (_hubs);
      if (_hubs.length) {
        setActiveHub (_hubs [0].id);
      }

    }
    fetchHubs ();

  }, []);

  useEffect (() => {
    async function fetchProductionData () {

      if (!activeHub) {
        setProductionData (null);
        return;
      }

      var db = firebase.firebase.firestore ();
      // TODO: Pagination!
      let production = [];
      let snapshot = await db.collection ('hubs').doc (activeHub).collection ('dailyProduction').orderBy ("date", "desc").get ();
      for (let doc of snapshot.docs) {
        let row = doc.data ();
        // row.date = new Date (row.date).toLocaleDateString ();
        // row.date = new Date (row.date);
        // row.date.setHours (0);
        // row.date.setMinutes (0);
        // row.date.setSeconds (0);
        // row.date.setMilliseconds (0);
        // row.date = row.date.toLocaleDateString ();
        const dateParts = row.date.split ('-');
        if (!dateParts || dateParts.length != 3) {
          continue;
        }
        row.date = new Date (dateParts [0], dateParts [1] - 1, dateParts [2]).toLocaleDateString ();
        // row.date = `${}`;

        // row.soapmakerHours = `${row.soapmakerHours} hours`;
        production.push (row);
      }
      setProductionData (production);
      setLoading (false);
    }
    fetchProductionData ();
    // setProductionData (production); // TEST
    // setLoading (false);             // TEST
  }, [activeHub]);

  // If the active hub changes, update this module with the new data.
  useEffect (() => {
    const state = appState.state;
    if (state.activeHub) {
      setActiveHub (state.activeHub);
      setLoading (false);
    }
  }, [appState.state.activeHub]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const calculateTotalHours = (row) => {
    if (!row.soapmakerHours || !row.soapmakers) {
      return 0;
    }
    return (parseInt (row.soapmakerHours) * parseInt (row.soapmakers));
  };

  const calculateBarsPerHour = (row) => {
    const totalHours = calculateTotalHours (row);
    if (!totalHours) {
      return '?';
    }
    return (Math.floor (parseInt (row.bars) / totalHours));
  };

  const getProductionData = () => {
    if (!productionData) { return null; }

    return productionData.map (row => {
      return (
        <React.Fragment>
          <p>{ row.date || '' }</p>
          <p>{ row.soapmakerHours || 0 }</p>
          <p>{ row.soapmakers || 0 }</p>
          <p>{ calculateTotalHours (row) }</p>
          <p>{ row.bars || 0 }</p>
          <p>{ calculateBarsPerHour (row) }</p>
        </React.Fragment>
      );
    });
  };

  const getRawProductionData = (startDate, endDate) => {
    if (!productionData) { return null; }

    let data = [];

    for (let row of productionData) {
      if (!row.date) { continue; }

      let dataRow = [];

      let date = getBaseDate (new Date (row.date));
      let start = getBaseDate (startDate);
      let end = getBaseDate (endDate);

      const afterStart = compareBaseDates (start, date);
      const beforeEnd = compareBaseDates (date, end);

      // console.log ('-------------');
      // console.log (`Date: ${date}`);
      // console.log (`Start: ${start}`);
      // console.log (`End: ${end}`);
      // console.log ('-------------\n');

      if (afterStart && beforeEnd) {
        // console.log ('TRUE');
        dataRow.push (
          row.date || '',
          row.soapmakerHours || 0,
          row.soapmakers || 0,
          calculateTotalHours (row),
          row.bars || 0,
          calculateBarsPerHour (row),
        );
        data.push (dataRow);
      // } else {
      //   console.log ('FALSE');
      }
    }
    return data.reverse ();
  };

  const getProductionTable = () => {
    return (
      <div className="production-data-table" id="production-data-table">
        <p class="production-data-table-header">Date</p>
        <p class="production-data-table-header">Shift</p>
        <p class="production-data-table-header">Soapmakers</p>
        <p class="production-data-table-header">Total Worker Hours</p>
        <p class="production-data-table-header">Bars Finished</p>
        <p class="production-data-table-header">Bars / Worker Hour</p>
        { getProductionData () }
      </div>
    );
  };

  const getHeaders = () => {
    return [
      'Date',
      'Shift',
      'Soapmakers',
      'Total Worker Hours',
      'Bars Finished',
      'Bars / Worker Hour',
    ];
  };

  const getRows = () => {
    if (!productionData) { return []; }

    return productionData.map (row => {
      return [
        row.date || '',
        row.soapmakerHours || 0,
        row.soapmakers || 0,
        calculateTotalHours (row),
        row.bars || 0,
        calculateBarsPerHour (row),
      ];
    });
  };

  function ExportProductionReportModal (props) {
    const [ start, setStart ] = useState (new Date ());
    const [ end, setEnd ] = useState (null);
    const [ formIsValid, setFormIsValid ] = useState (false);

    useEffect (() => {
      setFormIsValid (start !== null && end !== null);
    }, [start, end]);

    const onChange = (dates) => {
      // These are set as Date objects.
      const [start, end] = dates;
      setStart (start);
      setEnd (end);
    };

    const onClickCancel = () => {
      props.closeModal ();
    };

    const onClickExport = () => {
      console.log ('Exporting production report...');

      const rawProductionData = getRawProductionData (start, end);

      if (!rawProductionData || !rawProductionData.length) {
        window.alert ('No data for the specified date range. Try a different date range, or contact support for assistance.');
        return;
      }

      const format = getLocalePrintFormat () || 'a4';
      const doc = new jsPDF ({ format, unit: "in" });
      doc.setFontSize (24);
      const hub = hubs.find (x => x.id === activeHub);
      doc.text (`Eco-Soap Bank ${hub.name}`, 1, 1); // BANG
      doc.setLineWidth (0.04);
      doc.setDrawColor(59, 181, 73);
      doc.line (1, 1.175, 3, 1.175);
      doc.setFontSize (20);
      doc.text (`Production Report`, 1, 1.5);
      doc.setFontSize (12);
      const dateRangeString = `Date Range: ${start ? start.toLocaleDateString () : '?'} - ${end ? end.toLocaleDateString () : '?'}`;
      doc.text (dateRangeString, 1, 2);

      doc.autoTable ({
        head: [ getHeaders () ],
        body: rawProductionData,
        startY: 3.5,
        headStyles: { fillColor: '#3bb54a' },
      });

      doc.setPage (1);

      const totalBars = rawProductionData.reduce ((acc, x) => { return acc + parseInt (x [4]); }, 0);
      const totalBarsString = `Total Bars Finished: ${totalBars.toLocaleString ()}`;
      doc.text (totalBarsString, 1, 2.5);

      const totalHours = rawProductionData.reduce ((acc, x) => { return acc + parseInt (x [3]); }, 0);
      const totalHoursString = `Total Worker Hours: ${totalHours.toLocaleString ()}`;
      doc.text (totalHoursString, 1, 2.75);

      const avgBarsPerHour = Math.floor (totalBars / totalHours);
      const avgBarsPerHourString = `Average Bars / Worker Hour: ${avgBarsPerHour.toLocaleString ()}`;
      doc.text (avgBarsPerHourString, 1, 3);

      // doc.output ('pdfobjectnewwindow', { filename: `Production Report` });
      doc.save ('Production Report', { returnPromise: true })
      .then (() => {
        props.closeModal ();
      })
      .catch ((error) => {
        console.log (error);
        window.alert (error);
      })
    };

    return (
      <React.Fragment>
        <h1>Export Production Report</h1>
        <div className="production-data-export-modal">
          <div className="production-data-export-modal-row">
            <p>Select Dates:</p>
            <input type="text" disabled value={`${start.toLocaleDateString ()} – ${end ? end.toLocaleDateString () : '?'}`} size="22" />
          </div>

          <DatePicker
            id="production-data-export-modal-date"
            selected={start}
            onChange={onChange}
            startDate={start}
            endDate={end}
            selectsRange
            inline
          />

          <div className="production-data-export-modal-buttons">
            <Button color="red" onClick={onClickCancel}>Cancel</Button>
            <Button color={'green'} disabled={formIsValid ? false : true} onClick={onClickExport}>Export Report</Button>
          </div>
        </div>
      </React.Fragment>
    );

  };

  const onClickExport = () => {
    props.showModal (<ExportProductionReportModal closeModal={props.closeModal} />);
  };

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        <div className="production-data">
          <div className="production-data-stats">
            { getName () }
            <div className="production-data-select-hub">
              <p>Hub:</p>
              <Select options={hubs} action={setActiveHub} />
            </div>
            <div className="production-data-tools">
              {/*<div className="production-data-tools-item">
                <img src={IconExport} height="26" />
                <ShallowLink fontSize={18} color="blue">Print</ShallowLink>
              </div>*/}
              <div className="production-data-tools-item" onClick={onClickExport}>
                <img src={IconExport} height="26" />
                <ShallowLink fontSize={18} color="blue" onClick={onClickExport}>Export</ShallowLink>
              </div>
            </div>

            {/* getProductionTable () */}
            <DatabaseView
              headers={ getHeaders () }
              rows={ getRows () }
            />
          </div>
        </div>
      </div>
    </div>
  );

}
