import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import '../../styles/App.css';
import '../Module.css';
import './Reporting.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';


export default function Reporting (props) {

  const appState = useContext (DataContext);
  const history = useHistory ();

  const [activeProperty, setActiveProperty] = useState (null);
  const [loading, setLoading] = useState (true);

  useEffect (() => {
    setLoading (false);
  }, []);

  const navigate = (path) => {
    console.log (`Navigating to ${path}...`);
    history.push (path);
  };

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const navigateToReporting = () => {
    navigate ('reporting');
  };

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getName ()}
        <Button onClick={navigateToReporting}>Submit a Report</Button>
      </div>
    </div>
  );

}
