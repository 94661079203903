import React, { Component } from 'react';
import '../../../styles/App.css';


class IconDashboard extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || '1rem';
    let height = this.props.height || '1rem';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
      	 width={this.props.width} height={this.props.height} viewBox="0 0 800 800" style={{enableBackground: 'new 0 0 800 800'}} aria-labelledby="title">

         <title>dashboard</title>

        <g>
        	<g>
        		<path style={{fill: color}} d="M375.7929,0.087c16.1426,0,32.2852,0,48.4278,0c0.56,1.0352,1.5759,0.7138,2.4276,0.7676
        			c18.192,1.1493,36.2488,3.4436,54.0631,7.2554c94.7627,20.2768,172.7373,68.1056,233.0596,143.9057
        			c49.7358,62.4975,77.7291,133.9498,84.804,213.5688c0.3018,3.3957-0.2715,6.9673,1.3516,10.2072c0,16.4029,0,32.8058,0,49.2087
        			c-1.1825,0.6715-0.722,1.8398-0.7926,2.8054c-1.3095,17.9136-3.5346,35.7155-7.3084,53.2747
        			c-20.3945,94.8952-68.4149,172.8975-144.4349,233.1705c-62.366,49.4473-133.6201,77.2797-212.9624,84.3214
        			c-3.3958,0.3014-6.9674-0.2715-10.2075,1.3516c-16.403,0-32.8059,0-49.2089,0c-0.9348-1.3646-2.3866-0.7418-3.5854-0.8304
        			c-33.8702-2.5009-67.0102-8.8119-99.121-19.8784c-87.3781-30.1136-156.7276-83.7369-207.5055-160.9523
        			C28.1712,562.564,7.3232,501.2336,1.4721,434.8036c-0.2879-3.2681,0.1707-6.6952-1.385-9.803c0-16.6633,0-33.3266,0-49.9898
        			c1.0572-0.5447,0.7236-1.5592,0.7834-2.4149c1.2531-17.9194,3.4745-35.7238,7.2334-53.2852
        			c20.2838-94.7637,68.1067-172.7424,143.9096-233.0662C214.512,36.5083,285.9641,8.5115,365.5854,1.4386
        			C368.9812,1.1369,372.5529,1.7101,375.7929,0.087z M673.8014,399.9717c-0.3003-151.3895-122.2797-273.6126-273.6326-273.6979
        			c-151.4352-0.0854-273.7091,122.0663-273.8939,273.3771c-0.1852,151.6956,122.2253,274.0066,273.572,274.0904
        			C551.2925,673.8253,673.5344,551.5657,673.8014,399.9717z"/>
        		<path style={{fill: '#FFFFFF'}} d="M673.8014,399.9717c-0.267,151.594-122.5089,273.8535-273.9545,273.7696
        			c-151.3466-0.0839-273.7572-122.3948-273.572-274.0904c0.1848-151.3109,122.4587-273.4625,273.8939-273.3771
        			C551.5217,126.3592,673.5011,248.5822,673.8014,399.9717z M421.754,495.589c-0.1451,0.3521-0.2198,0.7549-0.443,1.0424
        			c-7.8196,10.074-15.6743,20.121-23.4605,30.2207c-1.0809,1.4021-2.4466,1.7358-4.0347,1.9695
        			c-26.3578,3.8785-43.4805,29.53-36.2076,54.9583c5.3137,18.5784,18.0651,29.2911,37.1696,32.1881
        			c16.5,2.5022,34.1724-5.9369,42.7571-20.8108c9.4741-16.4146,8.7688-32.7729-1.8814-48.4838
        			c-1.6052-2.3679-1.9547-3.8615-0.3116-6.3981c7.5659-11.6801,14.953-23.479,22.2074-35.3556
        			c1.5258-2.4979,2.9222-2.8982,5.6432-2.0899c45.4954,13.5155,83.041,38.4352,110.943,77.1837
        			c1.786,2.4803,2.7821,2.175,4.5566,0.1947c3.5588-3.9713,7.4575-7.6431,10.942-11.6752
        			c50.8641-58.8571,71.7913-127.0403,61.503-204.0559c-7.876-58.9584-34.0703-109.3803-77.894-149.5775
        			c-63.3733-58.129-138.3733-79.9344-222.6878-63.5652c-131.3555,25.502-219.1936,148.6125-202.1738,280.939
        			c7.394,57.4874,32.1062,106.5583,72.7327,147.7685c2.2893,2.3222,3.2431,2.1113,5.0511-0.4581
        			c10.7843-15.3261,23.6472-28.7296,38.5162-40.1213c45.4345-34.8089,97.0587-48.5282,153.7428-44.7811
        			C419.546,494.7557,420.7741,494.6507,421.754,495.589z"/>
        		<path style={{fill: color}} d="M421.754,495.589c-0.9799-0.9383-2.208-0.8333-3.3299-0.9075
        			c-56.684-3.7471-108.3082,9.9723-153.7428,44.7811c-14.869,11.3917-27.732,24.7952-38.5162,40.1213
        			c-1.808,2.5695-2.7618,2.7803-5.0511,0.4581c-40.6264-41.2102-65.3387-90.2811-72.7327-147.7685
        			c-17.0198-132.3265,70.8183-255.437,202.1738-280.939c84.3146-16.3693,159.3145,5.4362,222.6878,63.5652
        			c43.8237,40.1971,70.018,90.6191,77.894,149.5775c10.2883,77.0156-10.6389,145.1989-61.503,204.0559
        			c-3.4845,4.0321-7.3832,7.7039-10.942,11.6752c-1.7745,1.9803-2.7706,2.2856-4.5566-0.1947
        			c-27.902-38.7485-65.4476-63.6682-110.943-77.1837c-2.7211-0.8083-4.1175-0.408-5.6432,2.0899
        			c-7.2544,11.8766-14.6415,23.6754-22.2074,35.3556c-1.6431,2.5366-1.2936,4.0302,0.3116,6.3981
        			c10.6502,15.7109,11.3555,32.0692,1.8814,48.4838c-8.5848,14.8739-26.2571,23.313-42.7571,20.8108
        			c-19.1045-2.897-31.8558-13.6097-37.1696-32.1881c-7.2729-25.4282,9.8498-51.0797,36.2076-54.9583
        			c1.588-0.2336,2.9538-0.5674,4.0347-1.9695c7.7862-10.0997,15.6408-20.1467,23.4605-30.2207
        			C421.5341,496.3439,421.6089,495.9411,421.754,495.589z M576.5229,305.4822c-1.2565,0.5784-1.8249,1.8052-2.6108,2.8138
        			c-56.7751,72.8607-113.5647,145.7102-170.2457,218.6441c-2.9295,3.7696-5.9128,6.0053-10.8983,6.7593
        			c-17.4414,2.6375-30.6306,17.6951-32.1429,35.813c-1.4366,17.21,9.3988,33.8475,25.9931,39.912
        			c16.6194,6.0738,35.4986,0.0973,45.6753-14.4591c10.2447-14.6537,9.5204-34.457-2.0248-47.7734
        			c-2.1165-2.4413-2.1495-3.9801-0.4764-6.6423c46.9837-74.7573,93.8775-149.571,140.7693-224.3859
        			c2.1324-3.4022,4.1085-6.9024,6.1527-10.3657c0.1998-0.2695,0.4092-0.5331,0.5862-0.8169
        			c0.0195-0.0312-0.1454-0.2574-0.2259-0.258C576.5607,304.7191,576.4886,305.0689,576.5229,305.4822z"/>
        		<path style={{fill: '#FFFFFF'}} d="M576.7189,305.8069c-2.0488,3.4546-4.0249,6.9547-6.1573,10.3569
        			c-46.8918,74.8149-93.7856,149.6286-140.7693,224.3859c-1.6731,2.6622-1.6402,4.201,0.4764,6.6423
        			c11.5452,13.3164,12.2695,33.1197,2.0248,47.7734c-10.1766,14.5564-29.0558,20.5329-45.6753,14.4591
        			c-16.5943-6.0646-27.4296-22.7021-25.9931-39.912c1.5123-18.1179,14.7016-33.1755,32.1429-35.813
        			c4.9855-0.754,7.9688-2.9897,10.8983-6.7593c56.681-72.9339,113.4706-145.7834,170.2457-218.6441
        			c0.7859-1.0086,1.3543-2.2355,2.6108-2.8138L576.7189,305.8069z"/>
        		<path style={{fill: '#FFFFFF'}} d="M576.5229,305.4822c-0.0343-0.4133,0.0378-0.7631,0.5518-0.759c0.0804,0.0006,0.2454,0.2268,0.2259,0.258
        			c-0.177,0.2838-0.3864,0.5474-0.5839,0.8212C576.7189,305.8069,576.5229,305.4822,576.5229,305.4822z"/>
        	</g>
        </g>
      </svg>

    );

  }
}

export default IconDashboard;
