import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, Auth } from '../utils/auth';
import { DataContext, Actions } from '../utils/data';
import '../styles/App.css';
import './Module.css';
import './Modal.css';

import Loader from './Loader';
import Button from './Button';
import Select from './Select';
import ShallowLink from './ShallowLink';
import DatabaseView from './DatabaseView';

import IconExport from '../resources/icons/export-active.png';


export default function Modal (props) {

  const appState = useContext (DataContext);

  const [ loading, setLoading ] = useState (true);
  //const [ content, setContent ] = useState (null);

  useEffect (() => {
    //setModal (props.children);
    setLoading (false);
  }, []);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const closeModal = () => {
    props.closeModal ();
  };

  let color = props.color ||'#2e2e2e';
  let style = props.style;

  if (!props.display) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-blocker" onClick={closeModal}></div>
      <div className="module modal" style={style}>
        <div className="module-color-stub" style={{ backgroundColor: color }}></div>
        <div className="modal-close-button" onClick={closeModal}>&#10006;</div>
        <div className={loading ? "module-loader-loading" : "module-loader"}>
          <Loader />
        </div>
        <div className={loading ? "module-inner-loading" : "module-inner"}>
          { getName () }
          <div className="modal-content">
            { props.children }
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
