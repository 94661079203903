import React, { Component, useContext } from 'react';
 
import '../styles/App.css';
import './Loader.css';


class Loader extends Component {
  state = {
    // style: {
    //   color: (this.props.color) || '#FFFFFF',
    // },
  };

  // componentWillMount () {
  //   this.setStyle ({color: this.getStyle ()});
  // }
  //
  // getStyle = () => {
  //   let style = {};
  //   if (this.props.color) {
  //     color = { ...style, this.props.color };
  //   }
  //   return style;
  // };
  //
  // setStyle = (style) => {
  //   this.setState ({ style: style });
  // };

  render () {
    // let color = this.state.style.color;

    return (
      <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
    );
  }
}

export default Loader;
