import React, { useEffect, useState, useContext } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import '../../styles/App.css';
import '../Module.css';
import './ProfileInfo.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';

import IconProfile from '../../resources/icons/svg/IconProfile';


export default function ProfileInfo (props) {

  const auth = useContext (AuthContext);
  const appState = useContext (DataContext);

  const [profile, setProfile] = useState (null);  // Don't cache from appState.state.profile, since this
  const [loading, setLoading] = useState (true);  // info is editable here and needs to be up to date.

  // On load, query profile data.
  useEffect (() => {
    props.setLoading (true);
    setLoading (true);
    let query = new Query (() => Db.queryProfile (auth.user.id));
    query.execute ()
      .then (result => {
        console.log ('ProfileInfo: Profile:', result);
        if (result) {
          setProfile (result);
          appState.dispatch ({ type: Actions.ADD, payload: { profile: result } });
        }
      })
      .catch (error => {
        console.log (error);
      })
      .finally (() => {
        setLoading (false);
        props.setLoading (false);
      });
    return query.cancel;
  }, []);

  const getProfileData = () => {
    let data = profile;
    if (!data) { return null; }

    return (
      <div className="profile-info">

        <div className="profile-info-top">
          <div className="profile-info-icon">
            <div className="profile-info-icon-icon">
              <IconProfile height="80px" width="80px" />
            </div>
            <div className="profile-info-icon-overlay">
              Change
            </div>
          </div>
          <div className="profile-info-name">
            <div className="profile-info-display-name">
              {data.displayName}
            </div>
            <div className="profile-info-title">
              {data.title}
            </div>
          </div>
        </div>

        <div className="profile-info-table">

          <div className="profile-info-table-col-1">
            <div className="profile-info-table-header">First Name</div>
            <div className="profile-info-table-value">{data.firstName}</div>
            <div className="profile-info-table-header">Middle Name</div>
            <div className="profile-info-table-value">{data.middleName}</div>
            <div className="profile-info-table-header">Last Name</div>
            <div className="profile-info-table-value">{data.lastName}</div>
          </div>

          <div className="profile-info-table-col-2">
            <div className="profile-info-table-header">Email</div>
            <div className="profile-info-table-value">{data.email}</div>
            <div className="profile-info-table-header">Skype</div>
            <div className="profile-info-table-value">{data.skype}</div>
            <div className="profile-info-table-header">Phone</div>
            <div className="profile-info-table-value">{data.phone}</div>
          </div>

        </div>
      </div>
    );
  }


  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={{gridColumn: '1 / 2', gridRow: '1 / 2'}}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getProfileData ()}
        <Button>Change</Button>
      </div>
    </div>
  );

}
