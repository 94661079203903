import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import * as Utils from '../../utils/utils';
import '../../styles/App.css';
import '../Module.css';
import './AuthorizedUsers.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';
import DatabaseView from '../../components/DatabaseView';

import IconPlus from '../../resources/icons/svg/IconPlus';
import IconProfile from '../../resources/icons/svg/IconProfile';


export default function AuthorizedUsers (props) {

  const appState = useContext (DataContext);

  const [users, setUsers] = useState (null);
  const [loading, setLoading] = useState (true);

  // On load, query profile data.
  useEffect (() => {
    props.setLoading (true);
    setLoading (true);
    let query = new Query (() => Db.queryUsersByProperty ((appState.properties || []).map (x => x.id)));
    query.execute ()
      .then (result => {
        console.log ('AuthorizedUsers: Users:', result);
        if (result) {
          setUsers (result);
          appState.dispatch ({ type: Actions.ADD, payload: { users: result } });
        }
      })
      .catch (error => {
        console.log (error);
      })
      .finally (() => {
        setLoading (false);
        props.setLoading (false);
      });
    return query.cancel;
  }, []);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case ('Active'):          return '#3bb54a';
      case ('Inactive'):        return '#FF0000';
      case ('Pending'):         return '#FF7F00';
      default:                  return '#282828';
    }
  };

  const getHeaders = () => {
    return [
      null, // Avatar
      'Name',
      'Title',
      'Properties',
      'Status',
      null, // Contact
      null, // Edit
    ];
  };

  const getRows = (users) => {
    return (users.length) ? data.map (user => {

      let avatar = getAvatar (user.avatar, user.displayName);
      let userName = user.displayName;
      let title = user.title;
      let properties = (user.properties || []).join (', ');       // TOOD
      let status = user.status;
      if (user.status) {
        user.status.style = { fontWeight: 700, color: getStatusColor (user.status.text) }
      }
      let contact = { component: <ShallowLink color="blue">Contact</ShallowLink> };
      let edit = { component: <ShallowLink icon="edit" color="blue">Edit</ShallowLink> };

      return [
        avatar,
        userName,
        title,
        properties,
        status,
        contact,
        edit,
      ];

    }) : [];
  };

  const getAvatar = (url, name) => {
    if (url) {
      // If we have a valid URL, just display the avatar as an img
      return { component: <img className="authorized-users-logo" src={url} alt={`${name}`} /> };
    } else {
      return { component: <IconProfile /> };
    }
  };


  let color = props.color ||'#4D4D4D';
  let style = props.style;

  let data = users || [];

  let headers = getHeaders ();
  let rows = getRows (data);

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getName ()}
        <DatabaseView headers={headers} rows={rows} />
      </div>
    </div>
  );

}
