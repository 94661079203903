import { Colors } from './colors';
// import Module from '../components/Module';

import ActiveProperty from '../components/modules/ActiveProperty';
import AuthorizedUsers from '../components/modules/AuthorizedUsers';
import Database from '../components/modules/Database';
import ImpactSummary from '../components/modules/ImpactSummary';
import InventoryReport from '../components/modules/InventoryReport';
import LatePayment from '../components/modules/LatePayment';
import MakePayment from '../components/modules/MakePayment';
import PaymentHistory from '../components/modules/PaymentHistory';
import Preferences from '../components/modules/Preferences';
import ProductionReport from '../components/modules/ProductionReport';
import ProductionData from '../components/modules/ProductionData';
import ProfileInfo from '../components/modules/ProfileInfo';
import Reporting from '../components/modules/Reporting';
import Roles from '../components/modules/Roles';
import SalesReport from '../components/modules/SalesReport';
import SchedulePickup from '../components/modules/SchedulePickup';
import ScheduledPickups from '../components/modules/ScheduledPickups';
import YourProperties from '../components/modules/YourProperties';


export class Modules {

  static modules = {

    activeProperty: {
      name: null,
      component: ActiveProperty,
      color: Colors.GRAY,
      columnSpan: 2,
      rowSpan: null,
    },

    authorizedUsers: {
      name: 'Authorized Users',
      component: AuthorizedUsers,
      color: null,
      columnSpan: 2,
      rowSpan: 1,
    },

    database: {
      name: 'Database',
      component: Database,
      color: Colors.GREEN,
      columnSpan: 2,
      rowSpan: null,
    },

    impactSummary: {
      name: 'Impact Summary',
      component: ImpactSummary,
      color: Colors.GREEN,
      columnSpan: 2,
      rowSpan: null,
    },

    inventoryReport: {
      name: 'Inventory',
      component: InventoryReport,
      color: Colors.ORANGE,
      columnSpan: 2,
      rowSpan: null,
    },

    latePayment: {
      name: null,
      component: LatePayment,
      color: Colors.RED,
      columnSpan: 2,
      rowSpan: null,
    },

    makePayment: {
      name: 'Make a Payment',
      component: MakePayment,
      color: Colors.BLUE,
      columnSpan: 1,
      rowSpan: 1,
    },

    paymentHistory: {
      name: 'Payment History',
      component: PaymentHistory,
      color: null,
      columnSpan: 2,
      rowSpan: 1,
    },

    preferences: {
      name: 'Preferences',
      component: Preferences,
      color: Colors.BLUE,
      columnSpan: null,
      rowSpan: null,
    },

    productionData: {
      name: 'Production Data',
      component: ProductionData,
      color: Colors.PURPLE,
      columnSpan: 2,
      rowSpan: null,
    },

    productionReport: {
      name: 'Production',
      component: ProductionReport,
      color: Colors.BLUE,
      columnSpan: 2,
      rowSpan: null,
    },

    profileInfo: {
      name: null,
      component: ProfileInfo,
      color: Colors.GREEN,
      columnSpan: null,
      rowSpan: null,
    },

    reporting: {
      name: 'Reporting',
      component: Reporting,
      color: Colors.BLUE,
      columnSpan: 2,
      rowSpan: null,
    },

    roles: {
      name: 'Roles',
      component: Roles,
      color: Colors.PURPLE,
      columnSpan: null,
      rowSpan: null,
    },

    salesReport: {
      name: 'Sales',
      component: SalesReport,
      color: Colors.GREEN,
      columnSpan: 2,
      rowSpan: null,
    },

    schedulePickup: {
      name: 'Schedule a Pickup',
      component: SchedulePickup,
      color: Colors.PURPLE,
      columnSpan: 1,
      rowSpan: 1,
    },

    scheduledPickups: {
      name: 'Scheduled Pickups',
      component: ScheduledPickups,
      color: null,
      columnSpan: 2,
      rowSpan: 1,
    },

    yourProperties: {
      name: 'Your Properties',
      component: YourProperties,
      color: null,
      columnSpan: 2,
      rowSpan: 1,
    },

  };

}
