import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import * as Utils from '../../utils/utils';
import '../../styles/App.css';
import '../Module.css';
import './YourProperties.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';
import DatabaseView from '../../components/DatabaseView';

import IconPlus from '../../resources/icons/svg/IconPlus';


export default function YourProperties (props) {

  const appState = useContext (DataContext);

  const [properties, setProperties] = useState (null);
  const [loading, setLoading] = useState (true);

  // Wait for data. The ActiveProperty module queries the DB and adds property data
  // to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.properties) {
      setProperties (state.properties);
      setLoading (false);
    }
  }, [appState.state.properties]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const getHeaders = () => {
    return [
      null,
      'Property Name',
      'Property Type',
      'Services',
      'Address',
      'Phone',
      null
    ];
  };

  const getRows = (data) => {
    return (data.length) ? data.map (property => {

      let p = property;
      console.log (property);

      // let dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };

      let logo = getLogo (property.logo, property.name);
      let propertyName = { text: property.name, modal: 'propertyDetails' };
      let propertyType = property.propertyType;
      let services = (property.services || []).join (', ');
      let address = { text: 'View', modal: 'propertyDetails' };
      let phone = property.phone;
      let edit = { component: <ShallowLink icon="edit" color="blue">Edit</ShallowLink> };

      return [
        logo,
        propertyName,
        propertyType,
        services,
        address,
        phone,
        edit,
      ];

    }) : [];
  };

  const getLogo = (url, name) => {
    if (url) {
      // If we have a valid URL, just display the logo as an img
      return { component: <img className="your-properties-logo" src={url} alt={`${name} logo`} /> };
    } else {
      // Otherwise, display an option to add a logo.
      return ({
        component: (
          <ShallowLink>
            <div className="your-properties-add-logo">
              <IconPlus color="blue" height="24px" width="24px" />
              <p>Add Logo</p>
            </div>
          </ShallowLink>
        )
      });
    }
  };


  let color = props.color ||'#4D4D4D';
  let style = props.style;

  let data = properties || [];

  let headers = getHeaders ();
  let rows = getRows (data);

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getName ()}
        <DatabaseView headers={headers} rows={rows} />
      </div>
    </div>
  );

}
