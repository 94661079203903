import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from "react-router-dom";
import { AuthContext } from '../utils/auth';
import Obfuscate from 'react-obfuscate';
import '../styles/App.css';
import '../styles/Support.css';

import Input from '../components/Input';
import Button from '../components/Button';
import ShallowLink from '../components/ShallowLink';

import logo from '../resources/img/logo.png';


export default function Support (props) {

  // const auth = useContext (AuthContext);

  // State
  // const [form, setForm] = useState ({});

  useEffect (() => {
    // console.log ('Support: Setting loading to false');
    props.setLoading (false);
  }, []);

  return (

    <div className="support">
      <h1>We're here to help.</h1>
      <p>Questions? Comments? Get in touch with us at:</p>
      <Obfuscate email="support@ecosoapbank.org" />
    </div>

  );

}
