import React, { useEffect, useState, useContext } from 'react';

import '../styles/App.css';
import './Select.css';


export default function Select (props) {

  const [selected, setSelected] = useState (props.selected || ( (props.options) ? props.options [0] : ''));
  const [focus, setFocus] = useState (false);
  const [disabled, setDisabled] = useState (props.disabled || false);

  useEffect (() => {
    setDisabled (props.disabled);
  }, [props.disabled]);

  // If we update the "selected" prop, force the value on the component.
  useEffect (() => {
    setSelected (props.selected);
  }, [props.selected]);


  const getTextStyle = () => {
    let textStyle = {};
    // if (props.disabled) {
    //   textStyle = { ...textStyle, ...styles.text.disabled };
    // }
    // if (props.color && styles.text [props.color]) {
    //   textStyle = { ...textStyle, ...styles.text [props.color]};
    // }
    // if (props.fontSize) {
    //   textStyle = { ...textStyle, fontSize: props.fontSize };
    // }
    // if (props.bold) {
    //   textStyle = { ...textStyle, fontWeight: 600 };
    // }
    return textStyle;
  };

  // setStyle = (style) => {
  //   setState ({ style: style });
  // };
  //
  // setSelectState = (selectState, value = true) => {
  //   // Options: hover, focus, disabled, default
  //   switch (buttonState) {
  //     case ('hover'):
  //       setState ({ hover: value });
  //       return;
  //     case ('focus'):
  //       setState ({ focus: value });
  //       setStyle ({
  //         text: value ? {...styles.text.focus} : null,
  //       });
  //       return;
  //     case ('disabled'):
  //       setState ({ disabled: value });
  //       setStyle ({
  //         text: {...styles.text.disabled},
  //       });
  //       return;
  //     default:
  //       setState ({ icon: null });
  //       setStyle ({
  //         text: null,
  //       });
  //   }
  // };

  const onFocus = (e) => {
    //setInputState ('focus');
  };

  const onBlur = (e) => {
    //setInputState ('focus', false);
  };

  const onChange = (e) => {
    if (!disabled) {
      setSelected (e.target.value);
      props.action (e.target.value);
    }
  }

  const getOptions = () => {
    let options = props.options;
    if (!options || !Array.isArray (options)) { return null; }
    return options.map ((option) => {
      return ( <option key={option.id} value={option.id}>{option.name}</option> );
    });
  };

  return (
    <div className="select-wrapper">
      <select value={selected} onChange={(e) => onChange (e)} disabled={disabled}>
        {getOptions ()}
      </select>
    </div>
  );

}
