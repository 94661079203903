import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { AuthContext } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import '../../styles/App.css';
import '../Module.css';
import './ActiveProperty.css';

import Loader from '../../components/Loader';
import ShallowLink from '../../components/ShallowLink';
import Select from '../../components/Select';


export default function ActiveProperty (props) {

  const appState = useContext (DataContext);
  const auth = useContext (AuthContext);

  const [properties, setProperties] = useState (null);
  const [activeProperty, setActiveProperty] = useState (appState.state.activeProperty);
  const [loading, setLoading] = useState (true);

  // On load, query properties and set the active property.
  useEffect (() => {
    // If we've already cached property data, we don't need to query again.
    // Only query fresh data once per user session or if the user refreshes.
    if (activeProperty) {
      setProperties (appState.state.properties);
      setActiveProperty (appState.state.activeProperty);
      setLoading (false);
      props.setLoading (false);
      return;
    }

    props.setLoading (true);
    setLoading (true);
    let query = new Query (Db.queryAllPropertyData);
    query.execute ()
      .then (result => {
        console.log ('ActiveProperty: Properties:', result);
        if (result.length) {
          setProperties (result);
          setActiveProperty (result [0]);
          appState.dispatch ({ type: Actions.ADD, payload: { properties: result } });
          appState.dispatch ({ type: Actions.ADD, payload: { activeProperty: result [0] } });
        }
      })
      .catch (error => {
        console.log (error);
      })
      .finally (() => {
        setLoading (false);
        props.setLoading (false);
      });
    return query.cancel;
  }, []);

  // Wait for data updates from app state so loading indicator displays if
  // necessary.
  useEffect (() => {
    const state = appState.state;
    if (state.activeProperty) {
      setActiveProperty (state.activeProperty);
      props.setLoading (false);
      setLoading (false);
    }
  }, [appState.state.activeProperty]);

  const onSelect = (selected) => {
    const newActiveProperty = properties.find (x => x.id == selected);
    // setLoading (true);
    props.setLoading (true);
    appState.dispatch ({ type: Actions.ADD, payload: { activeProperty: newActiveProperty } });
  };

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  const propertyNames = (properties || []).map (x => ({ name: x.name, id: x.id }));

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        <div className="active-property">

          <div className="module-subtitle">
            Active Property:
          </div>

          <div className="active-property-select">
            <Select options={propertyNames} selected={(activeProperty || {}).id} action={(selected) => onSelect (selected)} />
          </div>

          <ShallowLink icon="edit" to="" color="blue" fontSize="1.2rem" iconSize="24px" bold>
            Edit Information
          </ShallowLink>

        </div>
      </div>
    </div>
  );

}
