import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Switch, Route, Redirect, Link, useLocation, useHistory } from "react-router-dom";
import { AuthContext } from './utils/auth';
import { FirebaseContext } from './utils/firebase';
import { Navigation } from './utils/navigation';
import { Role, Roles } from './utils/roles';  // TEMP
import * as Utils from './utils/utils';
import Obfuscate from "react-obfuscate";

import './styles/App.css';

import Loader from './components/Loader';
import Sidebar from './components/Sidebar';
import Screen from './screens/Screen';
import Modal from './components/Modal';

// Images
// =============================================================================
import logo from './resources/img/logo.png';


// App
// =============================================================================
export default function App () {

  const auth = useContext (AuthContext);
  const firebase = useContext (FirebaseContext);
  const location = useLocation ();
  const history = useHistory ();

  const navigate = (path) => {
    console.log (`Navigating to ${path}...`);
    history.push (path);
  };

  let scr = Navigation.paths [location.pathname] || null;
  if (!scr) {
    // Path not found—redirect to default screen.
    console.log ("Path not found—redirecting to default screen.");
    scr = {};
    Navigation.forgetPath ();
    navigate ('/');
  } else {
    console.log (`Path found—setting path to ${location.pathname}`);
    Navigation.setPath (location.pathname);
  }


  // Enable support for Firebase Emulator Suite
  //firebase.functions.useFunctionsEmulator ("http://localhost:5001");

  // Also emulate Firestore?          // TEMP: Reenable this
  // var db = firebase.firestore();
  // if (location.hostname === "localhost") {
  //   db.settings({
  //     host: "localhost:8080",
  //     ssl: false
  //   });
  // }

  // TEST: Firebase Functions
  // var helloWorld = firebase.functions.httpsCallable ('helloWorld');
  // useEffect (() => {
  //   helloWorld ()
  //     .then ((result) => {
  //       alert (result.data.text);
  //       console.log (result);
  //     })
  //     .catch ((error) => {
  //       console.log (error);
  //     });
  // }, []);

  // State
  const [loading, setLoading] = useState (true);
  const [screens, setScreens] = useState (null);
  const [showModal, setShowModal] = useState (false);
  const [modalContent, setModalContent] = useState (null);
  // const [moduleData, setModuleData] = useState ({});

  const shouldRedirectToLogin = (path) => {
    const shouldRedirect = !auth.user && path !== '/login';
    if (shouldRedirect) {
      if (location.pathname !== '/login') {
        Navigation.rememberPath (location.pathname);
      } else {
        //Navigation.forgetPath ();
      }
      return true;
    }
    //Navigation.forgetPath ();
    return false;
  }

  // Subscribe to auth redirect requests.
  useEffect (() => {
    auth.attachObserver ('redirect', navigate);
    return auth.detachObserver ('redirect', navigate);
  }, []);

  function LoadingBlocker (props) {
    if (!props.display) { return null; }
    return (
      <div id="loading-blocker">
        <Loader />
      </div>
    );
  }

  function ScreenName (props) {
    if (!props.display) { return null; }
    const Icon = scr.icon || null;
    return (
      <div className="screen-name">
        <Icon height="44px" width="44px" />
        <div className="screen-name-text">{scr.name}</div>
      </div>
    );
  }

  // Generate screens only on app load and on auth change.
  const generateScreens = async () => {
    console.log ('Generating screens...');
    // if (!auth.user) { return; }
    let _screens = [];
    const role = auth.getRole ();
    let i = 0;
    for (let [path, scr] of Object.entries (Navigation.paths)) {
      const modules = Roles.getModules (role, scr.code);

      // Is this screen a component or just a group of modules?
      const ScreenToShow = scr.component || Screen;

      _screens.push (
        <Route exact path={path} key={'Route_' + i}>
          {shouldRedirectToLogin (path) ? (
            <Redirect to={{ pathname: '/login' }} /> ) : (
              <ScreenToShow
                key={'Route_' + i}
                modules={modules}
                // appData={moduleData}      // () => { return moduleData; }
                setLoading={setLoading}
                showModal={ (content) => { setShowModal (true); setModalContent (content); } }
                closeModal={ () => setShowModal (false) }
                // getData={getData}
                // setData={setData}
              />
            )}
        </Route>
      );
      i++;
    }
    console.log ('Screens:', _screens);
    setScreens (_screens);
  };
  // Subscribe to auth status requests.
  useEffect (() => {
    auth.attachObserver ('status', generateScreens);
    return auth.detachObserver ('status', generateScreens);
  }, []);


  console.log (location.pathname);


  return (

    <div className="app-layout">

      <Modal display={showModal} closeModal={() => setShowModal (false)}>
        { modalContent }
      </Modal>

      <Sidebar display={scr.showSidebar || false} active={scr.name} />

      <div className="screen-content">

        <ScreenName display={scr.showSidebar} />

        <Switch>
          { screens }
        </Switch>

        <LoadingBlocker display={loading} />

        <div className="app-layout-footer">
          Questions? Feedback? Get in touch at <Obfuscate email="support@ecosoapbank.org" />.
        </div>

      </div>

    </div>

  );

}
