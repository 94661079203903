import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import * as Utils from '../../utils/utils';
import '../../styles/App.css';
import '../Module.css';
import './ScheduledPickups.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';
import DatabaseView from '../../components/DatabaseView';


export default function ScheduledPickups (props) {

  const appState = useContext (DataContext);

  const [scheduledPickups, setScheduledPickups] = useState (null);
  const [loading, setLoading] = useState (true);

  // Wait for data. The ActiveProperty module queries the DB and adds property data
  // to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.activeProperty) {
      setScheduledPickups (state.activeProperty.scheduledPickups);
      setLoading (false);
    }
  }, [(appState.state.activeProperty || {}).scheduledPickups]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case ('Active'):          return '#3bb54a';
      case ('Complete'):        return '#3bb54a';
      case ('Cancelled'):       return '#FF0000';
      case ('Out for Pickup'):  return '#FF7F00';
      case ('Closed'):          return '#A0A0A0';
      default:                  return '#282828';
    }
  };

  const getHeaders = () => {
    return [
      'Confirmation Code',
      'Status',
      'Ready Date',
      'Date Picked Up',
      'Cartons',
      'Notes',
    ];
  };

  const getRows = (data) => {
    return (data.length) ? data.map (pickup => {

      let dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };

      let confirmationCode = { text: pickup.confirmationCode, modal: 'pickupDetails'};  // TODO: Modals
      let status = pickup.status;
      if (pickup.status) {
        pickup.status.style = { fontWeight: 700, color: getStatusColor (pickup.status.text) }
      }
      let readyDate = (pickup.readyDate) ? pickup.readyDate.toIsoDate ().toLocaleDateString (undefined, dateOptions) : null;
      let datePickedUp = (pickup.datePickedUp) ? pickup.datePickedUp.toIsoDate ().toLocaleDateString (undefined, dateOptions) : null;
      let cartons = pickup.cartons;
      let notes = { text: 'View', modal: 'pickupDetails' };  // TODO: Modals

      return [
        confirmationCode,
        status,
        readyDate,
        datePickedUp,
        cartons,
        notes,
      ];

    }) : [];
  };

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  let data = scheduledPickups || [];

  let headers = getHeaders ();
  let rows = getRows (data);

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getName ()}
        <DatabaseView headers={headers} rows={rows} />
      </div>
    </div>
  );

}
