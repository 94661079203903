import React, { Component } from 'react';
import '../../../styles/App.css';


class IconProfile extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title id="title">profile</title>

        <defs>
          <clipPath id="clip-path">
            <circle cx="512" cy="798.43" r="300" fill="none" />
          </clipPath>
          <style>
            {`.icon-profile-foreground{fill:#FFFFFF}`}
          </style>
        </defs>
        <circle cx="512" cy="512" r="512" fill={this.getColor ()} />
        <g clipPath="url(#clip-path)">
          <circle className="icon-profile-foreground" cx="512" cy="507.43" r="384" />
        </g>
        <circle className="icon-profile-foreground" cx="518.62" cy="274.18" r="150" />
      </svg>

    );

  }
}

export default IconProfile;
