import React, { Component } from 'react';
import '../../../styles/App.css';


class IconProperties extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title id="title">properties</title>

        <defs>
          <style>
            {`.cls-2{fill:#fff}`}
          </style>
        </defs>

        <circle cx="512" cy="512" r="512" fill={color}/>
        <path className="cls-2" d="M315.27 604.53h-19.14v-64.21h19.14V514.8h-19.14v-64.21h19.14v-25.53h-19.14v-64.21h19.14v-25.52h-19.14v-64.22h19.14v-9.27h179.2V225.7H162v455.2h119.84v-51.69h33.43zm-47.71 0h-64.21v-64.21h64.21zm0-89.73h-64.21v-64.21h64.21zm0-89.74h-64.21v-64.21h64.21zm0-89.73h-64.21v-64.22h64.21z"/>
        <path className="cls-2" d="M315.27 259.6v538.7h141.82v-61.16h109.82v61.16h141.82V259.6zm124.92 448.33h-76v-76h76zm0-106.2h-76v-76h76zm0-106.19h-76v-76h76zm0-106.2h-76v-76h76zM550 707.93h-76v-76h76zm0-106.2h-76v-76h76zm0-106.19h-76v-76h76zm0-106.2h-76v-76h76zm109.8 318.59h-76v-76h76zm0-106.2h-76v-76h76zm0-106.19h-76v-76h76zm0-106.2h-76v-76h76z"/>
        <path className="cls-2" d="M529.53 225.7v36.14h179.2v9.27h19.14v64.22h-19.14v25.52h19.14v64.21h-19.14v25.53h19.14v64.21h-19.14v25.52h19.14v64.21h-19.14v24.68h33.43v51.69H862V225.7zm291.12 378.83h-64.21v-64.21h64.21zm0-89.73h-64.21v-64.21h64.21zm0-89.74h-64.21v-64.21h64.21zm0-89.73h-64.21v-64.22h64.21z"/>

      </svg>

    );

  }
}

export default IconProperties;
