import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../utils/auth';
import { Navigation } from '../utils/navigation';
import { Roles } from '../utils/roles';
import { Link } from "react-router-dom";

import '../styles/App.css';
import './Sidebar.css';

import logo from '../resources/img/logo.png';

import IconLogOut from '../resources/icons/svg/IconLogOut';


function Sidebar (props) {

  const auth = useContext (AuthContext);

  // State
  const [role, setRole] = useState (auth.getRole ());

  useEffect (() => {
    setRole (auth.getRole ());
  }, [(auth.user || {}).role]);

  // // Subscribe to auth status requests.
  // const setRoleCallback = async () => {
  //   // if (!auth.user) { return; }
  //   setRole (auth.getRole ());
  // };
  // useEffect (() => {
  //   auth.attachObserver ('status', setRoleCallback);
  //   return auth.detachObserver ('status', setRoleCallback);
  // }, []);

  const getNavItems = () => {
    // Nav Items:
    // - Get the user's role
    // - Get the screens for the role
    // - Get the data for each screen
    let navItems = [];
    if (!role) {
      return;
    }
    console.log ('SIDEBAR ROLE: ', role);
    const screens = Roles.getScreens (role);
    for (let [key, value] of Object.entries (screens)) {
      const scr = Navigation.screens [key];
      if (!scr) { continue; }

      const name = scr.name || '';
      const isActive = (name === props.active);
      const nameStyle = isActive ? 'sidebar-nav-item-name-active' : 'sidebar-nav-item-name';
      const iconColor = isActive ? 'blue' : 'gray';
      const Icon = scr.icon || null;
      const path = scr.path;
      const action = scr.action;

      // TODO: Make style dynamic / move to CSS

      navItems.push (
        <Link to={path} className="sidebar-nav-item" key={name}>
          <Icon color={iconColor} height="24px" width="24px" />
          <div className={nameStyle}>{name}</div>
        </Link>
      );
    }

    // Log Out Link
    navItems.push (
      <div className="sidebar-nav-item" key={'Log Out'} onClick={auth.signOut}>
        <IconLogOut color="gray" height="24px" width="24px" />
        <div className="sidebar-nav-item-name">Log Out</div>
      </div>
    );

    return navItems;
  };

  if (!props.display) { return null; }
  return (
    <div className="sidebar-main">
      <img src={logo} className="logo-esb" alt="Eco-Soap Bank Logo" />
      <div className="sidebar-nav">
        {getNavItems ()}
      </div>
    </div>
  );
}

export default Sidebar;
