import React, { createContext } from 'react';
// import { Auth } from './auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
// import 'firebase/database';  // TODO: Enable when needed
import 'firebase/firestore';
// import 'firebase/storage';
// import 'firebase/messaging';
import 'firebase/functions';

let firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export class Firebase {
  constructor () {
    // Prevent duplicate Firebase instances.
    if (firebase.apps.length) { return; }

    console.log ('Initializing Firebase...');

    let firebaseApp = firebase.initializeApp (firebaseConfig);

    console.log (firebaseApp);

    this.firebase = firebaseApp;
    this.auth = firebase.auth ();
    this.functions = firebase.functions ();
    window.firebase = firebaseApp;
    window.firebaseAuth = this.auth;
    window.firebaseFunctions = this.functions;
    // Auth.firebase = this.auth;
  }
};

export const FirebaseContext = React.createContext ({
  firebase: Firebase,  // Make sure we throw an error if a component doesn't find a matching provider.
});
