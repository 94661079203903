import { useEffect } from 'react';
import { CancelablePromise } from './navigation';

export class Db {

  static properties = [
    {
      id: 'ID_TestHotel1',
      name: 'Test Hotel 1',
      propertyType: 'Hotel',
      rooms: 150,
      services: ['Soap', 'Bottles', 'Linens'],
      users: [
        'userid1',
        'userid2',
        'userid3',
      ],
      shippingAddress: {
        address1: 'Test Line 1',
        address2: 'Test Line 2',
        address3: 'Test Line 3',
        city: 'Test City 1',
        state: 'Test State',
        postalCode: '11111',
        country: 'Test Country',
        formattedAddress: [],
      },
      phone: '+21 893 2811',
      logo: 'https://picsum.photos/200/200#1',
      impact: {
        soapRecycled: '45.90 kg',
        bottlesRecycled: '39.64 kg',
        peopleServed: '459',
        womenEmployed: '3',
      },
      mediaThumb: 'https://picsum.photo/400/300#1',
      hub: {
        email: 'testhub1@ecosoapbank.org',
        phone: '+21 893 2811',
      },
      payment: {
        invoicePeriodStartDate: '2020-01-01',
        invoicePeriodEndDate: '2020-03-31',
        amountDue: '225.00 USD',
        dueDate: '2020-01-01',
      },
      paymentHistory: [
        {
          id: 'ID_Payment1',
          invoiceCode: '111 111',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-01-01',
          invoicePeriodStartDate: '2019-01-01',
          invoicePeriodEndDate: '2019-03-31',
          dueDate: '2019-01-01',
          paymentMethod: 'ACH',
        },
        {
          id: 'ID_Payment2',
          invoiceCode: '222 222',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-04-01',
          invoicePeriodStartDate: '2019-04-01',
          invoicePeriodEndDate: '2019-06-30',
          dueDate: '2019-04-01',
          paymentMethod: 'ACH',
        },
        {
          id: 'ID_Payment3',
          invoiceCode: '333 333',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-07-01',
          invoicePeriodStartDate: '2019-07-01',
          invoicePeriodEndDate: '2019-09-30',
          dueDate: '2019-07-01',
          paymentMethod: 'Credit / Debit',
        },
      ],
      scheduledPickups: [
        {
          id: 'ID_TestPickup1',
          confirmationCode: 'P92 W43',
          status: {
            // (style property added below)
            text: 'Cancelled',
          },
          readyDate: '2019-03-15',
          datePickedUp: null,
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 4,
          },
        },
        {
          id: 'ID_TestPickup2',
          confirmationCode: 'H4Y Q25',
          status: {
            // (style property added below)
            text: 'Complete',
          },
          readyDate: '2019-06-11',
          datePickedUp: '2019-06-12',
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 7,
          },
          notes: null,
        },
        {
          id: 'ID_TestPickup3',
          confirmationCode: 'GH3 3WZ',
          status: {
            // (style property added below)
            text: 'Out for Pickup',
          },
          readyDate: '2020-01-01',
          datePickedUp: null,
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 5,
          },
        },
      ],
    },
    {
      id: 'ID_TestHotel2',
      name: 'Test Hotel 2',
      propertyType: 'Hotel',
      rooms: 150,
      services: ['Soap', 'Bottles', 'Linens', 'Paper'],
      users: [
        'userid1',
      ],
      shippingAddress: {
        address1: 'Test Line 1',
        address2: 'Test Line 2',
        address3: 'Test Line 3',
        city: 'Test City 2',
        state: 'Test State',
        postalCode: '22222',
        country: 'Test Country',
        formattedAddress: [],
      },
      phone: '+22 893 2812',
      logo: 'https://picsum.photos/200/200#2',
      impact: {
        soapRecycled: '32.45 kg',
        bottlesRecycled: '32.24 kg',
        peopleServed: '241',
        womenEmployed: '1',
      },
      mediaThumb: 'https://picsum.photo/400/300#2',
      hub: {
        email: 'testhub2@ecosoapbank.org',
        phone: '+22 893 2812',
      },
      payment: {
        invoicePeriodStartDate: '2020-04-01',
        invoicePeriodEndDate: '2020-06-30',
        amountDue: '225.00 USD',
        dueDate: '2020-04-01',
      },
      paymentHistory: [
        {
          id: 'ID_Payment1',
          invoiceCode: '111 111',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-01-01',
          invoicePeriodStartDate: '2019-01-01',
          invoicePeriodEndDate: '2019-03-31',
          dueDate: '2019-01-01',
          paymentMethod: 'ACH',
        },
        {
          id: 'ID_Payment2',
          invoiceCode: '222 222',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-04-01',
          invoicePeriodStartDate: '2019-04-01',
          invoicePeriodEndDate: '2019-06-30',
          dueDate: '2019-04-01',
          paymentMethod: 'ACH',
        },
        {
          id: 'ID_Payment3',
          invoiceCode: '333 333',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-07-01',
          invoicePeriodStartDate: '2019-07-01',
          invoicePeriodEndDate: '2019-09-30',
          dueDate: '2019-07-01',
          paymentMethod: 'Credit / Debit',
        },
      ],
      scheduledPickups: [
        {
          id: 'ID_TestPickup4',
          confirmationCode: 'P92 W43',
          status: {
            // (style property added below)
            text: 'Closed',
          },
          readyDate: '2019-03-15',
          datePickedUp: null,
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 4,
          },
        },
        {
          id: 'ID_TestPickup5',
          confirmationCode: 'H4Y Q25',
          status: {
            // (style property added below)
            text: 'Closed',
          },
          readyDate: '2019-06-11',
          datePickedUp: '2019-06-12',
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 7,
          },
          notes: null,
        },
        {
          id: 'ID_TestPickup6',
          confirmationCode: 'GH3 3WZ',
          status: {
            // (style property added below)
            text: 'Out for Pickup',
          },
          readyDate: '2020-01-01',
          datePickedUp: null,
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 5,
          },
        },
      ],
    },
    {
      id: 'ID_TestHotel3',
      name: 'Test Hotel 3',
      propertyType: 'Hotel',
      rooms: 150,
      services: ['Soap', 'Bottles', 'Carbon'],
      users: [
        'userid1',
        'userid3',
      ],
      shippingAddress: {
        address1: 'Test Line 1',
        address2: 'Test Line 2',
        address3: 'Test Line 3',
        city: 'Test City 3',
        state: 'Test State',
        postalCode: '33333',
        country: 'Test Country',
        formattedAddress: [],
      },
      phone: '+23 893 2813',
      logo: null,
      impact: {
        soapRecycled: '21.30 kg',
        bottlesRecycled: '16.48 kg',
        peopleServed: '196',
        womenEmployed: '1',
      },
      mediaThumb: 'https://picsum.photo/400/300#3',
      hub: {
        email: 'testhub3@ecosoapbank.org',
        phone: '+23 893 2813',
      },
      payment: {
        invoicePeriodStartDate: '2020-07-01',
        invoicePeriodEndDate: '2020-09-30',
        amountDue: '225.00 USD',
        dueDate: '2020-07-01',
      },
      paymentHistory: [
        {
          id: 'ID_Payment1',
          invoiceCode: '111 111',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-01-01',
          invoicePeriodStartDate: '2019-01-01',
          invoicePeriodEndDate: '2019-03-31',
          dueDate: '2019-01-01',
          paymentMethod: 'ACH',
        },
        {
          id: 'ID_Payment2',
          invoiceCode: '222 222',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-04-01',
          invoicePeriodStartDate: '2019-04-01',
          invoicePeriodEndDate: '2019-06-30',
          dueDate: '2019-04-01',
          paymentMethod: 'ACH',
        },
        {
          id: 'ID_Payment3',
          invoiceCode: '333 333',
          invoice: '',
          amountPaid: '225.00',
          amountDue: '225.00',
          date: '2019-07-01',
          invoicePeriodStartDate: '2019-07-01',
          invoicePeriodEndDate: '2019-09-30',
          dueDate: '2019-07-01',
          paymentMethod: 'Credit / Debit',
        },
      ],
      scheduledPickups: [
        {
          id: 'ID_TestPickup4',
          confirmationCode: 'P92 W43',
          status: {
            // (style property added below)
            text: 'Closed',
          },
          readyDate: '2019-03-15',
          datePickedUp: null,
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 4,
          },
        },
        {
          id: 'ID_TestPickup5',
          confirmationCode: 'H4Y Q25',
          status: {
            // (style property added below)
            text: 'Closed',
          },
          readyDate: '2019-06-11',
          datePickedUp: '2019-06-12',
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 7,
          },
          notes: null,
        },
        {
          id: 'ID_TestPickup6',
          confirmationCode: 'GH3 3WZ',
          status: {
            // (style property added below)
            text: 'Out for Pickup',
          },
          readyDate: '2020-01-01',
          datePickedUp: null,
          cartons: {
            modal: 'pickupDetails',  // TODO: Modals
            text: 5,
          },
        },
      ],
    },
  ];

  static profile = {
    firstName:    'Scott',
    middleName:   'Tiberius',
    lastName:     'Johnson',
    displayName:  'Scott Johnson',
    title:        'General Manager',
    avatar:       'https://picsum.photo/100/100',
    email:        'scottpgh@hilton.com',
    skype:        'scott.t.johnson',
    phone:        '+1 123 456 7890',
    preferences:  [
      {
        id: 'testPreference1',
        name: 'Test Preference 1',
        value:  'Test Value 1',
      },
      {
        id: 'testPreference2',
        name: 'Test Preference 2',
        value:  'Test Value 2',
      },
      {
        id: 'testPreference3',
        name: 'Test Preference 3',
        value:  'Test Value 3',
      },
    ],
    roles: [
      {
        id: 'HotelPartner',
        name: 'Hotel Partner',
      },
    ],
  };

  static users = [
    {
      id:           'userid1',
      firstName:    'Stacy',
      middleName:   '',
      lastName:     'Davidson',
      displayName:  'Stacy Davidson',
      title:        'General Manager',
      avatar:       'https://picsum.photos/100/100',
      email:        'stacy@test.com',
      skype:        'stacy.davidson',
      phone:        '+1 123 456 7890',
      status: {
        text: 'Pending',
      },
      properties: [ // The properties this user has access to.
        'ID_TestHotel1',
        'ID_TestHotel2',
        'ID_TestHotel3',
      ],
    },
    {
      id:           'userid2',
      firstName:    'Peter',
      middleName:   '',
      lastName:     'Pettigrew',
      displayName:  'Peter Pettigrew',
      title:        'Director of Housekeeping',
      avatar:       null,
      email:        'peter@test.com',
      skype:        'peter.pettigrew',
      phone:        '+1 123 456 7890',
      status: {
        text: 'Active',
      },
      properties: [ // The properties this user has access to.
        'ID_TestHotel1',
      ],
    },
    {
      id:           'userid2',
      firstName:    'Wayne',
      middleName:   'Kilgore',
      lastName:     'Newton',
      displayName:  'Wayne Newton',
      title:        'Director of Sales',
      avatar:       'https://picsum.photos/100/100',
      email:        'wayne@test.com',
      skype:        'wayne.newton',
      phone:        '+1 123 456 7890',
      status: {
        text: 'Inactive',
      },
      properties: [ // The properties this user has access to.
        'ID_TestHotel1',
        'ID_TestHotel3',
      ],
    },

  ];

  // TODO: Query database.
  // TODO
  static queryPropertyNames = () => {
    // return new CancelablePromise ((resolve, reject) => {
    console.log ('Querying property names...');
    return new Promise ((resolve, reject) => {
      const propertyNames = Db.properties.map (property => {
        return ({
          name: property.name,
          id: property.id,
        });
      });
      setTimeout (() => { resolve (propertyNames); }, 1000);
    });
  };

  // TODO: Query database.
  // TODO: Make sure dates convert properly from stored ISO format.
  // TODO
  static queryAllPropertyData = () => {
    console.log ('Querying all property data...');
    // return new CancelablePromise ((resolve, reject) => {
    return new Promise ((resolve, reject) => {
      const propertyData = Db.properties;
      setTimeout (() => { resolve (propertyData); }, 1000);
    });
  };

  // TODO: Query database.
  // TODO
  static queryProfile = (uid) => {
    console.log ('Querying user profile data...');
    // return new CancelablePromise ((resolve, reject) => {
    return new Promise ((resolve, reject) => {
      const profile = Db.profile;
      setTimeout (() => { resolve (profile); }, 1000);
    });
  };

  // TODO: Query database.
  // TODO
  // properties: Array of property IDs
  static queryUsersByProperty = (properties) => {
    console.log ('Querying users by property...');
    // return new CancelablePromise ((resolve, reject) => {
    return new Promise ((resolve, reject) => {
      const users = Db.users;
      setTimeout (() => { resolve (users); }, 1000);
    });
  };


  //static query







}


// export function useQuery () {
//
//   return
// }

const QueryStatus = {
  PENDING: 'pending',
  LOADING: 'loading',
  COMPLETE: 'complete',
  CANCELLED: 'cancelled',
  ERROR: 'error',
};
Object.freeze (QueryStatus);


export class Query {

  // static reason = {
  //   canceled: 'canceled',
  // };

  constructor (request) {
    this.request = request;
    this.status = QueryStatus.PENDING;
    // return this.execute (request, index);
  }

  execute = async () => {
    this.status = QueryStatus.LOADING;
    const result = await this.request ()
      .catch (error => {
        this.status = QueryStatus.ERROR;
        throw error;
      })
    if (this.status !== QueryStatus.CANCELLED) {
      this.status = QueryStatus.COMPLETE;
      return result;
    } else {
      throw { reason: QueryStatus.CANCELLED };
    }
  };

  cancel = () => {
    // if (!this.status = QueryStatus.COMPLETE) {
      this.status = QueryStatus.CANCELLED;
    // }
  };

}
