import React, { Component } from 'react';
import '../../../styles/App.css';


class IconPayment extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title id="title">payment</title>

        <circle cx="512" cy="512" r="512" fill={color}/>
        <path d="M559.93 768.85V837H476.8v-68.15c-79.37-7.48-137-35.94-179.72-77.13l68.15-96.6c36.69 36 92.1 65.9 160.25 65.9 43.43 0 71.14-18.72 71.14-42.68 0-28.46-33-39.69-86.12-50.93-81.62-16.47-196.94-37.41-196.94-155.76 0-75.65 58.44-142.28 163.24-154.26V187h83.13v71.14c59.9 7.49 113.07 30.7 155 66.65l-70.38 92.11c-41.19-34.45-96.6-51.67-140.79-51.67-42.68 0-59.16 17.22-59.16 38.94 0 26.21 30.71 35.19 86.12 45.68 82.37 17.22 196.2 41.18 196.2 154.26 0 89.89-58.41 152.01-166.99 164.74z" fill="#fff"/>

      </svg>

    );

  }
}

export default IconPayment;
