import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import '../../styles/App.css';
import '../Module.css';
import './Preferences.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';

import IconProfile from '../../resources/icons/svg/IconPreferences';


export default function Preferences (props) {

  const appState = useContext (DataContext);

  const [preferences, setPreferences] = useState (null);
  const [loading, setLoading] = useState (true);

  // Wait for data. The ProfileInfo module queries the DB and adds profile data
  // to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.profile) {
      setPreferences (state.profile.preferences);
      setLoading (false);
    }
  }, [(appState.state.profile || {}).preferences]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1 style={{marginBottom: '0'}}>{name}</h1> );
    }
  };

  const getPreferences = () => {
    let data = preferences;
    if (!data) { return null; }

    return (
      <div className="preferences">
        <div className="preferences-table">
        {
          data.map (preference => {
            return (
              <React.Fragment key={preference.id}>
                <div className="preferences-table-header">{preference.name}</div>
                <div className="preferences-table-value">{preference.value}</div>
              </React.Fragment>
            );
          })
        }
        </div>
      </div>
    );
  }

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={{gridColumn: '2 / 3', gridRow: '1 / 2'}}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        <div className="preferences-top">
          <div className="preferences-icon">
            <IconProfile height="50px" width="50px" />
          </div>
          {getName ()}
        </div>
        {getPreferences ()}
        <Button>Change</Button>
      </div>
    </div>
  );

}
