// Date methods
// =============================================================================
// Date.toIso ()
// -------------
// Similar to Date.toISOString (), but doesn't include time.
Object.defineProperty (Date.prototype, 'toIso', {
  value () {
    // Date format (ISO 8601): "yyyy-mm-dd"
    let date = this;
    let year = date.getUTCFullYear ().toString ();
    let month = (date.getUTCMonth () + 1).toString ().padWithZeroes (2);
    let day = date.getUTCDate ().toString ().padWithZeroes (2);
    return (`${year}-${month}-${day}`);
  }
});

// String.toIsoDate ()
// -------------------
// Converts a string in ISO 8601 format to Date format.
Object.defineProperty (String.prototype, 'toIsoDate', {
  value () {
    // Date format (ISO 8601): "yyyy-mm-dd"
    let str = this.valueOf ();
    const [year, month, day] = str.split ('-');
    return new Date (year, month, day);
  }
});

// String.padWithZeroes ()
// -----------------------
// Manual implementation of padStart () for wider browser support.
// Pads the current string with another string (multiple times, if needed)
// until the resulting string reaches the given length. The padding is
// applied from the start of the given string.
Object.defineProperty (String.prototype, 'padWithZeroes', {
  value (targetLength) {
    let str = this.valueOf ();
    let len = str.length;
    if (!targetLength) { return str; }
    let newStr = '';
    for (let i = targetLength; i > len; i--) {
      newStr += '0';
    }
    newStr += str;
    return newStr;
  }
});




// Object methods
// =============================================================================
// isSubset ()
// -----------
// Checks if object a is a subset of object b.
export const isSubset = (a, b) => {
  for (let [key, value] of Object.entries (a)) {
    if (b [key] !== value) { return false; }
  }
  return true;
};


// Locale methods
// =============================================================================

export const getLocale = () => {
  // We get something like ["en-US", "en"].
  const browserLocales = (navigator.languages === undefined) ?
    [navigator.language] : navigator.languages;

  if (!browserLocales || !browserLocales.length) {
    return null;
  }

  // Return just the country code.
  return browserLocales [0].split ('-') [1];
}


export const getLocalePrintFormat = () => {

  const letterLocales = [
    'US',  // United States
    'CA',  // Canada
    'CL',  // Chile
    'CO',  // Colombia
    'CR',  // Costa Rica
    'MX',  // Mexico
    'PA',  // Panama
    'DO',  // Dominican Republic
    'PH',  // Philippines
  ];

  const locale = getLocale ();
  if (!locale || letterLocales.includes (locale)) {
    return 'letter';
  }
  return 'a4';

}

// getBaseDate (date)
// ------------------
// Returns a date in [year, month, day] format (UTC values).
export const getBaseDate = (date) => {
  let year = date.getUTCFullYear ();
  let month = date.getUTCMonth ();
  let day = date.getUTCDate ();

  return [year, month, day];
};


// compareBaseDates (date1, date2)
// ------------------
// Returns true if date1 comes before or on date2. Accepts base dates as returned from
// getBaseDate (...).
export const compareBaseDates = (date1, date2) => {
  // if (   // <-- EXTREMELY dumb, dumb bug.
  //   date1 [0] <= date2 [0] &&
  //   date1 [1] <= date2 [1] &&
  //   date1 [2] <= date2 [2]
  // ) {
  date1 = `${date1 [0]}`.padWithZeroes (4) + `${date1 [1]}`.padWithZeroes (2) + `${date1 [2]}`.padWithZeroes (2);
  date2 = `${date2 [0]}`.padWithZeroes (4) + `${date2 [1]}`.padWithZeroes (2) + `${date2 [2]}`.padWithZeroes (2);
  if (date1 <= date2) {
    return true;
  } else {
    return false;
  }
};
