import React, { useEffect, createContext, useContext, useReducer } from 'react';


// DataContext
// =============================================================================
const Actions = {
  ADD: 'Add or update a field',
  REMOVE: 'Remove a field',
};

const initialState = {};
const DataContext = createContext (initialState);

const { Provider } = DataContext;

const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer ((state, action) => {
    switch (action.type) {
      case Actions.ADD:
        return { ...state, ...action.payload };
      case Actions.REMOVE:
        let newState = { ...state };
        delete newState [action.payload];
        return newState;
      default:
        throw new Error ();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { Actions, DataContext, DataProvider };
