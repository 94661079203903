import React, { Component } from 'react';
import '../../../styles/App.css';


class IconPickups extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title id="title">pickups</title>

        <defs>
          <style>
            {`.cls-2,.cls-3{fill:#fff;stroke:${color};stroke-miterlimit:10}.cls-2{stroke-width:9.49px}.cls-3{stroke-width:8.46px}`}
          </style>
        </defs>

        <circle cx="512" cy="512" r="512" fill={color}/>
        <path className="cls-2" d="M237 363.92V787l126.92-126.92V237L237 363.92z"/>
        <path className="cls-3" transform="rotate(-90 575.46 448.54)" d="M363.92 237H787v423.08H363.92z"/>
        <path className="cls-3" transform="rotate(-90 448.54 575.46)" d="M237 363.92h423.08V787H237z"/>
        <path className="cls-2" d="M660.08 363.92V787L787 660.08V237L660.08 363.92z"/>

      </svg>

    );

  }
}

export default IconPickups;
