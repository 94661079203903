import { Modules } from './modules';
import { Auth } from './auth';
import { Navigation } from './navigation';

// const screens = Navigation.screens;
const modules = Modules.modules;


export const Role = {
  ADMIN: 'admin',
  EMPLOYEE: 'employee',
  HUB_ADMIN: 'hubAdmin',
  HUB_USER: 'hubUser',
  HOTEL_PARTNER: 'hotelPartner',
  HOTEL_USER: 'hotelUser',
  CORPORATE_PARTNER: 'corporatePartner',
  // NONE: 'none',
};


export class Roles {

  static roles = {
    admin: {
      name: 'Admin',
      // dataPermissions: {
      //   hubs: ['read'],
      // },
      screens: {
        dashboard: {
          modules: [
            // modules.impactSummary,
            modules.productionReport,
            modules.salesReport,
            modules.inventoryReport,
            modules.productionData,
          ],
        },
        database: {
          modules: [
            modules.database,
          ],
        },
        wiki: {

        },
        support: {

        },
      },
    },
    employee: {
      name: 'Employee',
      screens: {

      },
    },
    god: {
      name: 'Admin',
      // dataPermissions: {
      //   hubs: ['read', 'write'],
      // },
      screens: {
        dashboard: {
          modules: [
            // modules.impactSummary,
            modules.productionReport,
            modules.salesReport,
            modules.productionData,
          ],
        },
        database: {
          modules: [
            modules.database,
          ],
        },
        wiki: {

        },
        support: {

        },
      },
    },
    hubAdmin: {
      name: 'Hub Admin',
      screens: {
        profile: {
          modules: [
            // modules.profileInfo,
            // modules.preferences,
            // modules.roles,
          ],
        },
        dashboard: {
          modules: [
            // modules.impactSummary,
            modules.reporting,
            modules.productionData,
          ],
        },
        users: {
          modules: [
            modules.authorizedUsers,
          ],
        },
        support: {
          // modules: [
          //
          // ],
        },
      }
    },
    hubUser: {
      name: 'Hub User',
      screens: {
        profile: {
          modules: [
            modules.profileInfo,
            modules.preferences,
            modules.roles,
          ],
        },
        dashboard: {
          modules: [
            modules.impactSummary,
            //modules.reporting,
          ],
        },
        // reporting: {
        //   // ?
        // },
        support: {
          // modules: [
          //
          // ],
        },
      }
    },
    hotelPartner: {
      name: 'Hotel Partner',
      screens: {
        profile: {
          modules: [
            modules.profileInfo,
            modules.preferences,
            modules.roles,
          ],
        },
        dashboard: {
          modules: [
            modules.activeProperty,
            modules.impactSummary,
            modules.schedulePickup,
            modules.makePayment,
          ],
        },
        payment: {
          modules: [
            modules.latePayment,
            modules.makePayment,
            modules.paymentHistory,
          ],
        },
        pickups: {
          modules: [
            modules.schedulePickup,
            modules.scheduledPickups,
          ],
        },
        properties: {
          modules: [
            modules.yourProperties,
          ],
        },
        users: {
          modules: [
            modules.authorizedUsers,
          ],
        },
        support: {
          modules: [

          ],
        },
      },
    },
    hotelUser: {
      name: 'Hotel User',
      screens: {

      }
    },
    corporatePartner: {
      name: 'Corporate Partner',
      screens: {

      }
    },
    // none: {
    //   name: 'None',
    //   screens: {
    //
    //   },
    // },
  };

  // static defaultRole = Role.HOTEL_PARTNER;
  static defaultRole = Role.HUB_USER;

  static getScreens = (role) => {
    // Return the screens associated with the specified role.
    const roles = Roles.roles;
    if (!role) { return roles [Roles.defaultRole].screens;  }
    // const userRole = roles [Role [role]];
    console.log (role);
    console.log (roles [role]);
    return roles [role || Roles.defaultRole].screens;
  };

  static getModules = (role, scr) => {
    // Return the array of modules for the given role and screen.
    const screens = Roles.getScreens (role);
    console.log (`User screens:`);
    console.log (screens);
    if (!role || !screens [scr]) { return []; }
    return screens [scr].modules || [];
  }

}
