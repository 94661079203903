import React, { useEffect, createContext, useContext } from 'react';
import { Role, Roles } from './roles';
import { Navigation } from './navigation';
import { useHistory, useLocation } from "react-router-dom";


export function GoogleAuthButton () {
  return (
    <div className="g-signin2" data-longtitle="true" data-width="250px" data-height="40px" data-onsuccess="onGoogleLoginSuccess" data-onfailure="onGoogleLoginFailure"></div>
  );
}


export function FBAuthButton () {

  const onFacebookLoginSuccess = () => {};

  useEffect (() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '623453211758558',
        cookie     : true,
        xfbml      : true,
        version    : 'v6.0'
      });

      window.FB.AppEvents.logPageView();

      // this.onFacebookLoginSuccess = () => { this.onFacebookLoginSuccess };

      // Check if user is already logged in with Facebook.
      window.FB.getLoginStatus (function (response) {
        // TODO
      });
    };

    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  });

  return (
    <div className="fb-login-button" data-width="250px" data-height="40px" data-size="large" data-button-type="login_with" data-auto-logout-link="true" data-use-continue-as="false" data-onlogin="this.onFacebookLoginSuccess"></div>
  );
}



export class Auth {

  constructor () {
    this.GoogleAuthButton = GoogleAuthButton;
    this.FBAuthButton = FBAuthButton;

    this.setFirebaseAuthObserver ();
    this.userIsInRegistrationFlow = false;
  }

  observers = [];

  attachObserver = (property, callback) => {
    const observer = { property: property, callback: callback };
    if (!this.observers.includes (observer)) {
      this.observers.push (observer);
    }
  }
  detachObserver = (property, callback) => {
    const toRemove = { property: property, callback: callback };
    this.observers = this.observers.filter (observer => observer !== toRemove);
  };
  notifyObservers = (property, value) => {
    // if (!this [property] && !value) { return; }
    console.log (`Notifying observers of auth.${property}: ${value}`);
    this.observers.forEach (x => {
      if (x.property === property) { x.callback (value); }
    });
  };

  // Fields
  // ---------------------------------------------------------------------------
  // status =
  // user =
  // redirect =

  // NOTE: We set window.firebaseAuth from /src/utils/firebase.js
  // NOTE: We set this.user in setFirebaseAuthObserver ()

  // Generic Auth Wrappers
  // ---------------------------------------------------------------------------
  setUser = (user) => {
    // Set this.user. If a user is already set, don't allow setting a new user.
    // this.user is set null by logging out.
    if (user && this.user) {
      console.warn ('Auth: this.user already set. Aborting.');
      return;
    } else {
      this.user = user;
    }
  };

  setStatus = (status) => {
    this.status = status;
  };

  setRole = (role) => {
    console.log (`Setting user role to ${role}`);
    this.user.role = role;
  };

  getRole = (role) => {
    // Return the role associated with the current user.
    return (this.user || {}).role;
  };

  getUserRole = async () => {
    // Get the role associated with the current user from the database.
    //if (!this.user || !this.user.uid) { return Roles.defaultRole; } // TODO: Is this what we want?
    if (!this.user || !this.user.uid) { console.log ('Role is null!'); return null; }

    var db = window.firebase.firestore ();

    let role = await db.collection ('users').doc (this.user.uid)
      .get ()
      .then ((snapshot) => {
        let user = snapshot.data ();
        console.log (user.role);
        console.log (Role [user.role]);
        return Role [user.role] || null;
      })
      .catch ((error) => {
        console.log (error);
        //return Roles.defaultRole;
        return null;
      });

    return role;
  };

  // getUserDataPermissions = async () => {
  //   // Get the data permissions associated with the current user from the
  //   // database.
  //   // TODO: Transfer to and read from database.
  //   if (!this.user || !this.user.uid || !this.user.role) { console.log ('Role is null!'); return null; }
  //
  //   return (Roles [this.user.role] || {}).dataPermissions || null;
  //
  // };

  userIsVerified = (user) => {
    return user.emailVerified;
  };

  getUserHubId = async () => {
    if (!this.user || !this.user.uid) { return null; }

    let db = window.firebase.firestore ();

    return await db.collection ('users').doc (this.user.uid)
      .get ()
      .then ((snapshot) => {
        let user = snapshot.data ();
        if (!user.hub || !user.hub.id) {
          return null;
        }
        return user.hub.id;
      })
      .catch ((error) => {
        console.log (error);
        return null;
      });

  };











  // Firebase Auth
  // ---------------------------------------------------------------------------

  // Authentication state observer
  setFirebaseAuthObserver = async () => {
    window.firebaseAuth.onAuthStateChanged (async (user) => {
      if (user) {
        // User is signed in.
        console.log ('Firebase Auth Observer: User is signed in.');
        console.log ('User', user);
        this.setUser (user);
        this.setRole (await this.getUserRole ());
        this.setStatus (true);
        this.notifyObservers ('status', this.status);
        // Check whether the user's email address has been verified.
        if (user.emailVerified) {
          // If so, navigate to the dashboard (or the initially requested path).
          if (Navigation.getPath () === '/login') {
            // User is authenticating via the Login screen.
            this.notifyObservers ('redirect', Navigation.restorePath () || Navigation.defaultScreen.path);
          } else {
            // User is authenticating via cache (not via the Login screen).
            // this.notifyObservers ('redirect', Navigation.restorePath () || Navigation.defaultScreen.path);
          }
        } else {
          // If not, we need to verify it.
          if (this.userIsInRegistrationFlow) {
            // If the user just registered, we need to send the verification email for the first time.
            this.sendEmailVerification (user);
          } else {
            // If the user isn't in the initial registration flow, we need to prompt them to either:
            // - Check their inbox for the verification email.
            // - Resend the verification email.
            this.notifyObservers ('redirect', '/verify');
          }
        }
      } else {
        // User is signed out.
        console.log ('Firebase Auth Observer: User is signed out.');
        this.setStatus (false);
        this.notifyObservers ('status', this.status);
        this.notifyObservers ('redirect', '/login');  // TODO: Send to a "logged out" screen.
        console.log (this.observers);
      }
    });
  };


  createUserWithEmailAndPassword = (email, password) => {
    console.log (`Attempting to create user ${email}...`);
    this.userIsInRegistrationFlow = true;
    return window.firebaseAuth.createUserWithEmailAndPassword (email, password)
    .then (() => {
      return ('User created successfully.');
    }).catch (error => {
      this.userIsInRegistrationFlow = false;
      console.log (`${error.code}: ${error.message}`);
      window.alert ('We encountered an error. Please try again later or contact us at support@ecosoapbank.org.');
    });
  };

  sendEmailVerification = (user) => {
    if (!user) {
      user = window.firebaseAuth.currentUser;
      if (!user) {
        alert ("We had a problem sending your verification email. Please get in touch at support@ecosoapbank.org");
        console.log ("Error sending verification email.");
      }
    }
    user.sendEmailVerification ()
    .then (() => {
      if (!this.userIsInRegistrationFlow) {
        window.alert ("Verification email sent. Please check your inbox.");
        this.notifyObservers ('redirect', '/login');
      }
      this.userIsInRegistrationFlow = false;
      console.log ("Verification email sent successfully.");
    }).catch ((error) => {
      this.userIsInRegistrationFlow = false;
      alert ("We had a problem sending your verification email. Please get in touch at support@ecosoapbank.org");
      console.log ("Error sending verification email.");
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    console.log (`Attempting to sign in ${email}...`);
    return window.firebaseAuth.signInWithEmailAndPassword (email, password)
    .then ((result) => {
      console.log ("Signed in.");
      return (true);
    }).catch (error => {
      console.log (`${error.code}: ${error.message}`);
      window.alert ('We encountered an error logging you in. Please try again later or contact us at support@ecosoapbank.org.');
      return (false);
    });
  };

  signOut = () => {
    window.firebaseAuth.signOut ()
    .then (() => {
      console.log ("Signed out.");
      Navigation.forgetPath ();
      // Navigation.setScreen ('Login');
      // TODO
      //
      //



    }).catch (error => {
      console.log (`${error.code}: ${error.message}`);
      window.alert ('We encountered an error logging you out. Please try again or contact us at support@ecosoapbank.org.');
    });
  };

  resetPassword = (email) => { window.firebaseAuth.sendPasswordResetEmail (email); };

  updatePassword = (password) => { window.firebaseAuth.currentUser.updatePassword (password); };

  // ---------------------------------------------------------------------------



  isUserEqual = (googleUser, firebaseUser) => {
    if (firebaseUser) {
      var providerData = firebaseUser.providerData;
      for (var i = 0; i < providerData.length; i++) {
        if (providerData[i].providerId === window.firebaseAuth.auth.GoogleAuthProvider.PROVIDER_ID &&
            providerData[i].uid === googleUser.getBasicProfile ().getId ()) {
          // We don't need to reauth the Firebase connection.
          return true;
        }
      }
    }
    return false;
  };

  onGoogleLoginSuccess = (googleUser) => {
    console.log('Google Auth Response', googleUser);

    // TODO: Use/store profile info
    var profile = googleUser.getBasicProfile ();
    console.log ('ID: ' + profile.getId ()); // IMPORTANT: Do not send to your backend! Use an ID token instead.
    console.log ('Name: ' + profile.getName ());
    console.log ('Image URL: ' + profile.getImageUrl ());
    console.log ('Email: ' + profile.getEmail ()); // This is null if the 'email' scope is not present.
    window.alert (profile.getName ());

    // We need to register an Observer on Firebase Auth to make sure auth is initialized.
    var unsubscribe = window.firebaseAuth.auth ().onAuthStateChanged (function (firebaseUser) {
      unsubscribe ();
      // Check if we are already signed-in Firebase with the correct user.
      if (!this.isUserEqual (googleUser, firebaseUser)) {
        // Build Firebase credential with the Google ID token.
        var credential = window.firebaseAuth.auth.GoogleAuthProvider.credential (
            googleUser.getAuthResponse ().id_token);
        // Sign in with credential from the Google user.
        window.firebaseAuth.auth ().signInWithCredential (credential).catch (function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...


        });
      } else {
        console.log ('User already signed-in Firebase.');
      }
    });

  };

  onGoogleLoginFaulure = () => {
    console.log ("Google login failed.");
  };

  onFacebookLoginSuccess = () => {
    window.alert ("Facebook login success.");
  };

  // TODO: Google AND Firebase?
  logout = () => {
    // Google
    if (window.gapi && window.gapi.auth2) {
      let auth2 = window.gapi.auth2.getAuthInstance ();
      if (auth2) {
        auth2.signOut ().then (function () {
          console.log ('Google User signed out.');
        });
      }
    }
    // Firebase
    window.firebaseAuth.auth ().signOut ().then (function () {
      // Sign-out successful.
      console.log ('Firebase User signed out.');
    }).catch (function (error) {
      // An error happened.
      console.log (error);
      // TODO
    });
  };

  testLogIn = (email, password) => {
    return new Promise ((resolve, reject) => {
      return ('/redirect');
    });
  }




};

// this.GoogleAuthButton = GoogleAuthButton;

export const AuthContext = React.createContext ({
  auth: undefined,  // Make sure we throw an error if a component doesn't find a matching provider.
//   user: 'Test User',
//   role: Role.HOTEL_PARTNER,
//   googleAuthButton: <GoogleAuthButton />,
//   fbAuthButton: <FBAuthButton />,
//   logIn: this.signInWithEmailAndPassword,
//   logOut: () => {}, // TODO
//   // forceGlobalUpdate: () => {},
});
