import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import * as Utils from '../../utils/utils';
import '../../styles/App.css';
import '../Module.css';
import './LatePayment.css';

import Loader from '../../components/Loader';
import ShallowLink from '../../components/ShallowLink';


export default function LatePayment (props) {

  const appState = useContext (DataContext);

  const [paymentData, setPaymentData] = useState (null);
  const [loading, setLoading] = useState (true);
  const [display, setDisplay] = useState (false);

  const paymentIsLate = (payment) => {
    // TODO: Adjust for timezone?
    // TODO: Adjust to account for multiple potentially late payments.
    if (!payment) { return null; }

    let today = new Date ();

    let dueDate = payment.dueDate.toIsoDate ();
    // Add a day to the due date, because that's when this payment would be officially late.
    dueDate.setDate (dueDate.getDate () + 1);
    return (today > dueDate);
  };

  const getLatePayment = () => {
    // TODO
  };

  // Wait for data. The ActiveProperty module queries the DB and adds property data
  // to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.activeProperty) {
      // Calculate whether there's a late payment.
      setDisplay (paymentIsLate (state.activeProperty.payment))
      setPaymentData (state.activeProperty.payment);
      setLoading (false);
    }
  }, [(appState.state.activeProperty || {}).payment]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  let data = paymentData;
  let amountDue = null;
  let dueDate = null;
  if (data) {
    amountDue = data.amountDue;
    dueDate = data.dueDate.toIsoDate ().toLocaleDateString ();
  }

  return (
    <div className="module" style={display ? {...style} : { display: 'none' }}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        <div className="late-payment">

          <div className="module-subtitle">
            Late Payment:
          </div>
          A payment of {amountDue} was due on {dueDate}. Please <ShallowLink to="" color="blue">make a payment</ShallowLink> soon.
        </div>
      </div>
    </div>
  );

}
