import React, { useEffect, useState } from 'react';
import { AuthContext, Auth } from '../utils/auth';
import '../styles/App.css';
import './Module.css';
import './DatabaseView.css';

import Loader from '../components/Loader';
import ShallowLink from '../components/ShallowLink';
import Select from '../components/Select';


export default function DatabaseView (props) {

  // const [rows, setRows] = useState ([]);
  const [show, setShow] = useState (5);
  const [page, setPage] = useState (1);
  const [loading, setLoading] = useState (true);
  const [pageOptionsDisabled, setPageOptionsDisabled] = useState (true);

  let keyMap = new WeakMap ();
  let keyMapIndex = 0;

  useEffect (() => {
    if (props.rows.length > show) {
      setPageOptionsDisabled (false);
    }
    setLoading (false);
  }, [props.rows]);

  useEffect (() => {
    setPageOptionsDisabled (!(props.rows.length > show));
  }, [show]);

  const getDatabaseNav = (data) => {
    let rows = props.rows || [];

    // TODO: Count this properly.
    let resultsCount = rows.length;
    let pageCount = Math.ceil (resultsCount / show);

    let showOptions = [
      { id: '5', name: '5' },
      { id: '10', name: '10' },
      { id: '20', name: '20' },
      { id: '50', name: '50' },
    ];
    let pageOptions = [];
    for (let i = 1; i <= pageCount; i++) {
      pageOptions.push ({ id: `${i}`, name: `${i}` });
    }
    // let pageOptionsDisabled = (pageOptions.length <= 1);
    let previousDisabled = page <= 1;
    let nextDisabled = page >= pageCount;

    return (
      <div className="database-nav">

        <div className="database-nav-results">
          {resultsCount} results
        </div>

        <div className="database-nav-show">
          Show: <Select options={showOptions} action={onSelectShow} selected={show} />
        </div>

        <div className="database-nav-page">
          Page: <Select options={pageOptions} action={onSelectPage} disabled={pageOptionsDisabled} selected={page} />
        </div>

        <div className="database-nav-previous">
          <ShallowLink color="blue" bold disabled={previousDisabled} onClick={onClickPrevious}>Previous</ShallowLink>
        </div>

        <div className="database-nav-next">
          <ShallowLink color="blue" bold disabled={nextDisabled} onClick={onClickNext}>Next</ShallowLink>
        </div>

      </div>
    );
  };

  const onSelectShow = (value) => {
    setShow (parseInt (value, 10));
    setPage (parseInt (1, 10));
  };

  const onSelectPage = (value) => {
    setPage (parseInt (value, 10));
  };

  const onClickPrevious = () => {
    if (page <= 1) { return; }
    setPage (parseInt (page - 1, 10));
  };

  const onClickNext = () => {
    let resultsCount = props.rows.length;
    let pageCount = Math.ceil (resultsCount / show);
    if (page >= pageCount) { return; }
    setPage (parseInt (page + 1, 10));
  };

  const getDatabaseTable = () => {
    if (!props.headers.length) {
      return (
        <div className="database-table-no-data">None</div>
      );
    }

    return (

      <table className="database-table">

        <thead className="database-table-header">
          <tr className="database-table-header-row">
            { getHeaders (props.headers) }
          </tr>
        </thead>

        <tbody>
          { getRows (props.headers, props.rows) }
        </tbody>

      </table>

    );
  };

  const getHeaders = (headers) => {
    return headers.map ((header, index) => {
      // return ( <th className="database-table-header-item" key={`${header}+${index}`}>{header}</th>);
      return ( <th className="database-table-header-item">{header}</th>);
    });
  };

  const getRows = (headers, rows) => {

    const startIndex = show * (page - 1);
    const endIndex = startIndex + show;

    const rowsToDisplay = rows.slice (startIndex, endIndex);

    return rowsToDisplay.map (row => {
      // let rowKey = getRowKey (row);
      // console.log (rowKey);
      let rowKey = getWeakKey (row);
      // return (
      //   <tr className="database-table-row" key={rowKey}>
      return (
        <tr className="database-table-row">
          {
            row.map ((item, index) => {
              let header = headers [index];
              // If the item is an object, we need to do something with it first.
              if (isDatabaseObject (item)) {
                return (prepareRowItem (item, rowKey, header));
              } else {
                let itemKey = getItemKey (item, rowKey, header);
                // return ( <td className="database-table-item" key={itemKey}>{item}</td> );
                return ( <td className="database-table-item">{item}</td> );
              }
            })
          }
        </tr>
      );
    })
  };

  // getRowKey = (row) => {
  //   if (!row.length) {
  //     return row;
  //   } else {
  //     let rowKey = row.map (x => {
  //       if (isDatabaseObject (x)) {
  //         console.log (x.text || x.component);
  //         return (x.text || x.component)
  //       } else {
  //         console.log (x);
  //         return x;
  //       }
  //     });
  //     console.log (rowKey.join ());
  //     return rowKey.join ();
  //   }
  // };
  //
  const getItemKey = (item, rowKey, header) => {
    if (isDatabaseObject (item)) {
      return (rowKey + header + (item.text || item.component));
    } else {
      return (rowKey + header + item);
    }
  };

  const getWeakKey = (obj) => {
    let key = keyMap.get (obj);
    if (!key) {
      key = 'weak-key-' + keyMapIndex++;
      keyMap.set (obj, key);
    }
  };

  const isDatabaseObject = (x) => {
    return (typeof x === 'object' && x !== null);
  };

  const prepareRowItem = (obj, rowKey, header) => {
    let itemKey = getItemKey (obj, rowKey, header);

    // Style
    let style = null;
    if (obj.hasOwnProperty ('style')) {
      style = obj.style;
    }

    // Link to a file
    if (obj.hasOwnProperty ('link')) {
      // return (
      //   <td className="database-table-item" key={itemKey + obj.link}>
      return (
        <td className="database-table-item">
          <a href={obj.link} target="_blank"><span style={style}>{obj.text}</span></a>
        </td>
      );
    }

    // Link to a screen   // TODO
    if (obj.hasOwnProperty ('screen')) {
      // return (
      //   <td className="database-table-item" key={itemKey + obj.screen}>
      return (
        <td className="database-table-item">
          <ShallowLink to={obj.screen} color="blue"><span style={style}>{obj.text}</span></ShallowLink>
        </td>
      );
    }

    // Link to a modal  // TODO
    if (obj.hasOwnProperty ('modal')) {
      // return (
      //   <td className="database-table-item" key={itemKey + obj.modal}>
      return (
        <td className="database-table-item">
          <ShallowLink color="blue"><span style={style}>{obj.text}</span></ShallowLink>
        </td>
      );
    }

    // Component
    if (obj.hasOwnProperty ('component')) {
      // return (
      //   <td className="database-table-item" key={itemKey + obj.component}>
      return (
        <td className="database-table-item">
          <span style={style}>{obj.component}</span>
        </td>
      );
    }


    // Style only (NOTE: Must have text property)
    // return (
    //   <td className="database-table-item" key={itemKey + obj.text}>
    return (
      <td className="database-table-item">
        <span style={style}>{obj.text}</span>
      </td>
    );

  };

  const onSelect = (selected) => {

  };

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  let databaseNav = getDatabaseNav ();
  let databaseTable = getDatabaseTable ();

  return (
    <div className="database-view">
      {databaseNav}
      {databaseTable}
      {databaseNav}
    </div>
  );

}
