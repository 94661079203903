import React, { Component } from 'react';
import '../../../styles/App.css';


class IconSupport extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title id="title">support</title>

        <circle cx="512" cy="512" r="512" fill={color}/>
        <path d="M510.2 420.65c22.5-15.3 40.5-27.9 40.5-46.8 0-19.8-14.4-35.1-53.1-35.1-34.2 0-69.3 18-94.5 46.8l-86.4-99c49.5-55.8 124.2-84.6 198-84.6 118.8 0 192.6 57.6 192.6 144 0 74.7-45.9 108.9-85.5 138.6-28.8 20.7-54 38.7-54 67.5 0 15.3 6.3 28.8 13.5 36l-119.7 30.6c-17.1-20.7-27.9-48.6-27.9-82.8 0-62.1 43.2-92.7 76.5-115.2zm6.3 232.2c45.9 0 84.6 38.7 84.6 84.6s-38.7 84.6-84.6 84.6-84.6-38.7-84.6-84.6 38.7-84.6 84.6-84.6z" fill="#fff"/>

      </svg>

    );

  }
}

export default IconSupport;
