import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import * as Utils from '../../utils/utils';
import '../../styles/App.css';
import '../Module.css';
import './PaymentHistory.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';
import DatabaseView from '../../components/DatabaseView';


export default function PaymentHistory (props) {

  const appState = useContext (DataContext);

  const [paymentHistory, setPaymentHistory] = useState (null);
  const [loading, setLoading] = useState (true);

  // Wait for data. The ActiveProperty module queries the DB and adds property data
  // to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.activeProperty) {
      setPaymentHistory (state.activeProperty.paymentHistory);
      setLoading (false);
    }
  }, [(appState.state.activeProperty || {}).paymentHistory]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const getHeaders = () => {
    return [
      'Invoice Period',
      'Amount Due',
      'Amount Paid',
      'Date Paid',
      'Payment Method',
      'Invoice',
      'Invoice Number',
    ];
  };

  const getRows = (data) => {
    return (data.length) ? data.map (payment => {

      let dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
      let invoiceStart = payment.invoicePeriodStartDate.toIsoDate ().toLocaleDateString (undefined, dateOptions);
      let invoiceEnd = payment.invoicePeriodEndDate.toIsoDate ().toLocaleDateString (undefined, dateOptions);

      let invoicePeriod = `${invoiceStart} – ${invoiceEnd}`;
      let amountDue = payment.amountDue;
      let amountPaid = payment.amountPaid;
      let datePaid = payment.date.toIsoDate ().toLocaleDateString (undefined, dateOptions);
      let paymentMethod = payment.paymentMethod;
      let invoice = { text: 'PDF', link: payment.invoice };
      let invoiceNumber = payment.invoiceCode;

      return [
        invoicePeriod,
        amountDue,
        amountPaid,
        datePaid,
        paymentMethod,
        invoice,
        invoiceNumber,
      ];

    }) : [];
  };


  let color = props.color ||'#4D4D4D';
  let style = props.style;

  let data = paymentHistory || [];

  let headers = getHeaders ();
  let rows = getRows (data);

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getName ()}
        <DatabaseView headers={headers} rows={rows} />
      </div>
    </div>
  );

}
