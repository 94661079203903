import React, { useState, useEffect } from 'react';
import { NavigationContext, Navigation, CancelablePromise } from '../utils/navigation'; // TODO: Cancelable promises, etc.
import { AuthContext, Auth } from '../utils/auth';
import { Db } from '../utils/db';
import { Link } from "react-router-dom";
import '../styles/App.css';
import '../styles/Screen.css';

import Input from '../components/Input';
import Button from '../components/Button';

// import Module from '../components/Module';

export default function Screen (props) {

  // const [loading, setLoading] = useState ({});
  // const [propertyData, setPropertyData] = useState ({});
  const [modules, setModules] = useState ([]);

  useEffect (() => {
    console.log ('Screen: Setting loading false');
    props.setLoading (false);
  }, []);

  const getScreenModules = () => {
    let modules = [];
    let i = 0;
    for (let [i, module] of Object.entries (props.modules)) {
      if (!module) { continue; }
      const Module = module.component;
      const name = module.name;
      const color = module.color;
      const colSpan = (module.columnSpan) ? `span ${module.columnSpan}` : null;
      const rowSpan = (module.rowSpan) ? `span ${module.rowSpan}` : null;;
      modules.push (
        <Module
          key={'Module_' + i}
          name={name}
          color={color}
          style={{ gridColumn: colSpan, gridRow: rowSpan }}
          setLoading={props.setLoading}
          showModal={props.showModal}
          closeModal={props.closeModal}
        />
      );
      i++;
    }
    return modules;
  };

  // Populate modules for this screen on mount.
  // let modules = [];
  useEffect (() => {
    const _modules = getScreenModules ();
    setModules (_modules);
    console.log ('Modules:', _modules);
  }, []);

  return (
    <div className="screen-modules">
      {modules}
    </div>
  );

}

// <ActiveProperty setActiveProperty={setActiveProperty} activeProperty={props.activeProperty} color="gray" style={{gridColumn: 'span 2'}} />
// <ImpactSummary name="Impact Summary" color="green" data={propertyData} style={{gridColumn: 'span 2'}} loading={loading.impactSummary} setLoading={setLoading} />
// <SchedulePickup name="Schedule Pickup" color="purple" data={propertyData} loading={loading.schedulePickup} setLoading={setLoading} style={{gridColumn: 'span 1', gridRow: 'span 1'}} />
// <MakePayment name="Make a Payment" color="blue" data={propertyData} style={{gridColumn: 'span 1', gridRow: 'span 1'}} loading={loading.makePayment} setLoading={setLoading} />
