import React, { Component } from 'react';
import '../../../styles/App.css';


class IconPreferences extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title>preferences</title>

        <circle style={{fill: color}} cx="512" cy="512" r="512"/>

        <path style={{fill: '#FFFFFF'}} d="M837,582.7183V441.1664H730.92a228.5917,228.5917,0,0,0-14.0677-33.9716l75.0571-75.0572L691.8169,232.0453l-75.0564,75.0565a228.5944,228.5944,0,0,0-33.9744-14.0692V186.9387H441.2342V293.0326a228.5948,228.5948,0,0,0-33.9207,14.0415L332.28,232.0408,232.1878,332.1332l75.0084,75.0084A228.5946,228.5946,0,0,0,293.1,441.1664H187V582.7183H293.1A228.6018,228.6018,0,0,0,307.17,616.6928L232.111,691.7515,332.2034,791.8439l75.0594-75.0594a228.59,228.59,0,0,0,34.11,14.1128h-.1391v106.164H582.7861V730.8973h-.1391a228.595,228.595,0,0,0,34.1641-14.141l74.9963,74.9962L791.9,691.66l-75.0212-75.0212A228.5976,228.5976,0,0,0,730.92,582.7183Zm-324.99,70.776A141.552,141.552,0,1,1,653.5621,511.9424,141.5519,141.5519,0,0,1,512.01,653.4943Z"/>

      </svg>

    );

  }
}

export default IconPreferences;
