import React, { Component } from 'react';
import '../../../styles/App.css';


class IconEdit extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title id="title">edit</title>

        <circle style={{fill: color}} cx="512" cy="512" r="512"/>
        <rect style={{fill: '#FFFFFF'}} x="284.8528" y="461.9626" width="446.7941" height="107.5752" transform="translate(-215.8275 510.4466) rotate(-45)"/>
        <rect style={{fill: '#FFFFFF'}} x="662.5465" y="257.0423" width="101.2472" height="107.5752" transform="translate(-10.9072 595.3274) rotate(-45)"/>
        <polygon style={{fill: '#FFFFFF'}} points="376.365 723.702 237 787 237 787 300.298 647.635 376.365 723.702"/>

      </svg>

    );

  }
}

export default IconEdit;
