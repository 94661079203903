import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext, Auth } from '../utils/auth';
import { Navigation } from '../utils/navigation';
import '../styles/App.css';
import '../styles/Verify.css';

import Button from '../components/Button';

import logo from '../resources/img/logo.png';


export default function Verify (props) {

  // State
  const [loading, setLoading] = useState (true);

  const auth = useContext (AuthContext);

  useEffect (() => {
    console.log ('Verify: Setting loading to false');
    props.setLoading (false);
  }, []);

  const onClickLogin = (e) => {
    if (auth.user) {
      // Log the user out to send them to the Login screen.
      auth.signOut ();
    } else {
      Navigation.nofifyObservers ('redirect', '/login');
    }
  };

  const onClickResend = (e) => {
    // Send verification email.
    auth.sendEmailVerification ();
  };

  return (

    <div className="fullscreen">
      <div className="card-main">
        <img src={logo} className="logo-esb" alt="Eco-Soap Bank Logo" />
        <h2>Please verify your email address to continue.</h2>
        <p>Check your inbox for a verification email.</p>
        <Button onClick={onClickLogin} className="verify-button">Ok, I've Verified</Button>
        <Button onClick={onClickResend} className="verify-button" color="gray">Resend Verification</Button>
        <a href="mailto:support@ecosoapbank.org">Need help?</a>
      </div>
    </div>
    
  );

}
