import React, { Component } from 'react';
 
import { AuthContext, Auth } from '../utils/auth';
import '../styles/App.css';
import '../styles/Register.css';

import Input from '../components/Input';
import Button from '../components/Button';
import ShallowLink from '../components/ShallowLink';

import logo from '../resources/img/logo.png';


class Register extends Component {

  state = {
    form: {
      Email: '',
      Password: '',
    },
  };

  componentDidMount () {
    this.props.setLoading (false);
  }

  onChangeValue = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState ({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
    console.log (`Setting ${name} to ${value}.`);
  };

  onSubmit = (e) => {
    // Attempt to register the user with email and password.
    const email = this.state.form.Email;
    const password = this.state.form.Password;
    console.log (this.state);
    Auth.createUserWithEmailAndPassword (email, password)
    .then ((result) => {
      console.log (result);

      window.alert ("Great! Please check your inbox for a verification email.");
      // Redirect to Login screen.

    }).catch ((error) => {
      console.log (error);
    });

  };

  render () {

    // TODO: 3PA
    // ----------------------------------
    // <Auth.GoogleAuthButton />
    // <Auth.FBAuthButton />
    // <div className="register-or">or</div>
    // ----------------------------------

    return (
      <div className="fullscreen">
        <div className="card-main">
          <img src={logo} className="logo-esb" alt="Eco-Soap Bank Logo" />
          <h1 className="register-h1">Portal Registration</h1>
          <Input name="Email" required value={this.state.form.Email} onChange={this.onChangeValue}></Input>
          <Input name="Password" required value={this.state.form.Password} onChange={this.onChangeValue}></Input>
          <Button onClick={this.onSubmit}>Register</Button>
          <ShallowLink to="/login">Already have an account?</ShallowLink>
        </div>

        <p className="fullscreen-footer">Questions? Feedback? Get in touch at <a href="mailto:support@ecosoapbank.org" target="_blank">support@ecosoapbank.org</a>.</p>
      </div>
    );
  }
}

export default Register;
