import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import '../../styles/App.css';
import '../Module.css';
import './Roles.css';

import Loader from '../../components/Loader';


export default function Roles (props) {

  const appState = useContext (DataContext);

  const [roles, setRoles] = useState (null);
  const [loading, setLoading] = useState (true);

  // Wait for data. The ProfileInfo module queries the DB and adds profile data
  // to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.profile) {
      setRoles (state.profile.roles);
      setLoading (false);
    }
  }, [(appState.state.profile || {}).roles]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };


  const getRoleData = () => {
    let data = roles;
    if (!data) { return null; }

    return (
      <div className="schedule-pickup">
      {
        data.map (role => {
          return (
            <React.Fragment key={role.id}>
              <div className="roles-list-role">{role.name}</div>
            </React.Fragment>
          );
        })
      }
      </div>
    );
  }

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={{gridColumn: '1 / 2', gridRow: '2 / 3'}}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getName ()}
        {getRoleData ()}
      </div>
    </div>
  );

}
