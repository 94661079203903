import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import { Db, Query } from '../../utils/db';
import '../../styles/App.css';
import '../Module.css';
import './SchedulePickup.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';


export default function SchedulePickup (props) {

  const appState = useContext (DataContext);

  const [activeProperty, setActiveProperty] = useState (null);
  const [loading, setLoading] = useState (true);

  // Wait for data. The ActiveProperty module queries the DB and adds property
  // data to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.activeProperty) {
      setActiveProperty (state.activeProperty);
      setLoading (false);
    }
  }, [appState.state.activeProperty]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const getPickupData = () => {
    let data = (activeProperty || {}).hub;
    if (!data) { return null; }

    return (
      <div className="schedule-pickup">
        <Button>Schedule Now</Button>
        <p>You can also contact our team at:</p>
        <ShallowLink color="blue" fontSize="1.2rem" key={data.email}>{data.email}</ShallowLink>
        <p className="schedule-pickup-phone">{data.phone}</p>
      </div>
    );
  }

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        {getName ()}
        {getPickupData ()}
      </div>
    </div>
  );

}
