import React, { Component } from 'react';
import '../../../styles/App.css';


class IconPlus extends Component {

  state = {

  };

  componentDidMount () {
    // this.props.setLoading (false);
  }

  getColor = () => {
    let color = this.props.color;
    switch (color) {
      case ('gray'):    return ('#4D4D4D');
      case ('green'):   return ('#3BB54A');
      case ('blue'):    return ('#3B88FD');
      case ('red'):     return ('#FF0000');
      case ('purple'):  return ('#7A2DBB');
      default:          return ('#4D4D4D');
    }
  };

  render () {
    let width = this.props.width || 'auto';
    let height = this.props.height || 'auto';
    let color = this.getColor ();

    return (

      <svg className="icon" mlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={this.props.width} height={this.props.height} aria-labelledby="title">

        <title id="title">plus</title>

        <circle style={{fill: color}} cx="512" cy="512" r="512"/>
        <rect style={{fill: '#FFFFFF'}} x="187" y="464.3256" width="650" height="95.3488" transform="translate(-0.0001 1023.9999) rotate(-90)"/>
        <rect style={{fill: '#FFFFFF'}} x="187" y="464.3256" width="650" height="95.3488" transform="translate(1024.0002 1023.9998) rotate(180)"/>

      </svg>

    );

  }
}

export default IconPlus;
