import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useDropzone } from 'react-dropzone'
import { AuthContext } from '../utils/auth';
import { FirebaseContext } from '../utils/firebase';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/App.css';
import '../styles/Reporting.css';

import Input from '../components/Input';
import Select from '../components/Select';
import Button from '../components/Button';
import ShallowLink from '../components/ShallowLink';

import logo from '../resources/img/logo.png';


function DropZone (props) {
  const onDrop = useCallback (acceptedFiles => {

    console.log (acceptedFiles);

    // TODO: Do something with the files

  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps({
      className: 'dropzone',
    })}>
      <input {...getInputProps()} />
        {
          props.children ?
          props.children :
          <p>(Drag and drop here, or click to select.)</p>
        }
        {/*
          isDragActive ?
            <p>Drag and drop here, or click to select.</p> :
            <p>Drag and drop here, or click to select.</p>
        */}
    </div>
  );
}


function ReportingCard (props) {
  const { value, children } = props;

  return (
    <div className="reporting-card" onClick={() => props.onClick (value)}>{children}</div>
  );
};


function SelectHub (props) {

  const onSelectHub = (value) => {
    const hub = value;
    props.onSelectHub (hub);
  };

  return (
    <React.Fragment>
      <h2 className="reporting-h2">Choose Hub</h2>
      <div className="reporting-select-grid">
      <ReportingCard value="arusha" onClick={onSelectHub} {...props}>Arusha</ReportingCard>
      <ReportingCard value="freetown" onClick={onSelectHub} {...props}>Freetown</ReportingCard>
        <ReportingCard value="kathmandu" onClick={onSelectHub} {...props}>Kathmandu</ReportingCard>
        <ReportingCard value="siemReap" onClick={onSelectHub} {...props}>Siem Reap</ReportingCard>
      </div>
      <div className="reporting-nav">
        Arrow
      </div>
    </React.Fragment>
  );
};


function SelectForm (props) {

  const onSelectForm = (value) => {
    const form = value;
    props.onSelectForm (form);
  };

  return (
    <React.Fragment>
      <h2 className="reporting-h2">Choose Form</h2>
      <div className="reporting-select-grid">
        <ReportingCard value="dailyProduction" onClick={onSelectForm} {...props}>Daily Production</ReportingCard>
        <ReportingCard value="orderPlaced" onClick={onSelectForm} {...props}>Order Placed</ReportingCard>
        <ReportingCard value="orderCompleted" onClick={onSelectForm} {...props}>Order Completed</ReportingCard>
      </div>
      {/* <Button color="gray" onClick={() => props.onSetView ('selectHub')}>Back</Button> */}
    </React.Fragment>
  );
};


function DailyProduction (props) {
  const [date, setDate] = useState (new Date ());
  const [bars, setBars] = useState (null);
  const [soapmakers, setSoapmakers] = useState (null);
  const [soapmakerHours, setSoapmakerHours] = useState (null);

  useEffect (() => {
    props.onChangeValue (null, { 'dp-form-date': date.toISOString ().split ('T') [0] });
  }, []);

  // useEffect (() => {
  //   console.log (bars);
  // }, [date, bars, soapmakers, soapmakerHours]);
  //
  const onChangeDate = (date) => {
    setDate (date);
    props.onChangeValue (null, { 'dp-form-date': date.toISOString ().split ('T') [0] });
  };

  // const onChangeBars = (e) => {
  //   console.log (e.currentTarget);
  //   setBars (e.currentTarget.getAttribute ('data-field').value);
  //   props.onChangeValue (e);
  // };
  //
  // const onChangeSoapmakers = (e) => {
  //   setSoapmakers (e.currentTarget.getAttribute ('data-field').value);
  //   props.onChangeValue (e);
  // };
  //
  // const onChangeSoapmakerHours = (e) => {
  //   setSoapmakerHours (e.currentTarget.getAttribute ('data-field').value);
  //   props.onChangeValue (e);
  // };

  console.log (props.valid);

  return (
    <React.Fragment>
      <h2 className="reporting-h2">Daily Production</h2>
      <form className="reporting-form">

        {/* Date */}
        <div className="centered-row">
          <label htmlFor="dp-form-date" className="reporting-label">Date</label>
          <DatePicker id="dp-form-date" selected={date} onChange={date => onChangeDate (date)} />
        </div>

        {/* Bars Produced */}
        <div className="form-column">
          {/*<label htmlFor="dp-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="dp-form-bars" data-field="dp-form-bars" name="How many bars were produced today?" type="number" onChange={props.onChangeValue}  />
        </div>

        {/* Soapmakers */}
        <div className="form-column">
          {/*<label htmlFor="dp-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="dp-form-soapmakers" data-field="dp-form-soapmakers" name="How many soapmakers worked today?" type="number" onChange={props.onChangeValue}  />
        </div>

        {/* Soapmaker Hours */}
        <div className="form-column">
          {/*<label htmlFor="dp-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="dp-form-soapmaker-hours" data-field="dp-form-soapmaker-hours" name="How many hours did soapmakers work?" type="number" onChange={props.onChangeValue}  />
        </div>

        {/* Soap Bar Photo */}
        {/* }<div className="form-column">
          <DropZone>
            <p>Upload a <strong>photo of a soap bar</strong>.</p>
            <p>(Drag and drop here, or click to select.)</p>
          </DropZone>
        </div> */}

        {/* Additional Photos */}
        {/* }<div className="form-column">
          <DropZone>
            <p>Upload any additional <strong>production photos</strong>.</p>
            <p>(Drag and drop here, or click to select.)</p>
          </DropZone>
        </div> */}

      </form>
      <div className="centered-row">
        <Button color="gray" onClick={() => props.onSetView ('selectForm')}>Back</Button>
        <Button
          color={"green"}
          onClick={props.onSubmit}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
};


function OrderPlaced (props) {
  const [startDate, setStartDate] = useState (new Date());

  return (
    <React.Fragment>
      <h2 className="reporting-h2">Order Placed</h2>
      <form className="reporting-form">

        {/* Date */}
        <div className="centered-row">
          <label htmlFor="op-form-date" className="reporting-label">Date Order Placed</label>
          <DatePicker id="op-form-date" selected={startDate} onChange={date => setStartDate (date)} />
        </div>

        {/* Buyer organization */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-buyer-name" data-field="op-form-buyer-name" name="Buyer organization" type="text" onChange={props.onChangeValue}  />
        </div>

        {/* Number of bars */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-bars" data-field="op-form-bars" name="Number of bars" type="number" onChange={props.onChangeValue}  />
        </div>

        {/* Price per bar (in local currency) */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-price-per-bar" data-field="op-form-price-per-bar" name="Price per bar" type="number" onChange={props.onChangeValue}  />
        </div>

        {/* Total revenue from sale */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-revenue" data-field="op-form-revenue" name="Total revenue from sale" type="number" onChange={props.onChangeValue}  />
        </div>

        {/* Beneficiaries */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-beneficiaries" data-field="op-form-beneficiaries" name="How many beneficiaries?" type="number" onChange={props.onChangeValue}  />
        </div>

        {/* Buyer type */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Select id="op-form-buyer-type" data-field="op-form-buyer-type" name="Type of buyer"
                  options={[
                    { id: null, name: 'Buyer type (select)' },
                    { id: 'ingo', name: 'International NGO/multilateral' },
                    { id: 'ngo', name: 'Local NGO' },
                    { id: 'school', name: 'School' },
                    { id: 'orphanage', name: 'Orphanage' },
                    { id: 'healthcare', name: 'Healthcare facility' },
                    { id: 'market', name: 'Local market (retail)' },
                    { id: 'other', name: 'Other' },
                  ]}
                  action={props.onChangeValue}
          />
        </div>

        {/* Buyer contact info */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-buyer-contact-name" data-field="op-form-buyer-contact-name" name="Buyer contact name" type="text" onChange={props.onChangeValue}  />
        </div>

        {/* Buyer contact name */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-buyer-contact-info" data-field="op-form-buyer-contact-info" name="Buyer contact information" type="text" onChange={props.onChangeValue}  />
        </div>

        {/* Notes */}
        <div className="centered-row">
          {/*<label htmlFor="op-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Input id="op-form-notes" data-field="op-form-notes" name="Notes" type="text" onChange={props.onChangeValue}  />
        </div>

      </form>
      <div className="centered-row">
        <Button color="gray" onClick={() => props.onSetView ('selectForm')}>Back</Button>
        <Button color="green" onClick={() => props.onSetView ('confirmation')}>Submit</Button>
      </div>
    </React.Fragment>
  );
};


function OrderCompleted (props) {
  const [startDate, setStartDate] = useState (new Date());

  return (
    <React.Fragment>
      <h2 className="reporting-h2">Order Completed</h2>
      <form className="reporting-form">

        {/* Date */}
        <div className="centered-row">
          <label htmlFor="oc-form-date" className="reporting-label">Date of Sale (Payment Received)</label>
          <DatePicker id="oc-form-date" selected={startDate} onChange={date => setStartDate (date)} />
        </div>

        {/* Order */}
        <div className="form-column">
          {/*<label htmlFor="oc-form-bars" className="reporting-label">How many bars were produced today?</label>*/}
          <Select id="oc-form-order" data-field="oc-form-order" name="Type of buyer"
                  options={[
                    { id: null, name: 'Order (select)' },
                    { id: 'test1', name: 'Test Org 1 (August 12, 2020)' },
                    { id: 'test2', name: 'Test Org 2 (August 14, 2020)' },
                  ]}
                  action={props.onChangeValue}
          />
        </div>




        {/* Proof of transaction */}
        <div className="form-column">
          <DropZone>
            <p>Upload a <strong>record of the transaction</strong>.</p>
            <p>(Drag and drop here, or click to select.)</p>
          </DropZone>
        </div>

        {/* Photos */}
        <div className="form-column">
          <DropZone>
            <p>Upload any relevant <strong>photos/videos</strong>.</p>
            <p>(Drag and drop here, or click to select.)</p>
          </DropZone>
        </div>

      </form>
      <div className="centered-row">
        <Button color="gray" onClick={() => props.onSetView ('selectForm')}>Back</Button>
        <Button color="green" onClick={() => props.onSetView ('confirmation')}>Submit</Button>
      </div>
    </React.Fragment>
  );
};


function Reporting (props) {

  const auth = useContext (AuthContext);
  const firebase = useContext (FirebaseContext);

  // State
  const [view, setView] = useState ('selectForm');
  // const [view, setView] = useState ('selectHub');
  const [hub, setHub] = useState ({});
  const [form, setForm] = useState ({});
  const [formData, setFormData] = useState ({});

  useEffect (() => {
    console.log ('Reporting: Setting loading to false');
    props.setLoading (false);
  }, []);


  const onSetView = (view) => {
    setView (view);
  };

  const onSelectHub = (hub) => {
    setHub (hub);
    setView ('selectForm');
    console.log (`Set Hub to ${hub}`);
  };

  const onSelectForm = (form) => {
    setView (form);
    setForm (form);
    setFormData ({});
    console.log (`Set form to ${form}`);
  };

  const onChangeValue = (e, update) => {
    if (e) {
      // Event trigger
      const target = e.currentTarget;
      console.log (target);
      const field = target.getAttribute ('data-field');
      const value = target.value;
      let newFormData = formData;
      newFormData [field] = value;
      console.log (newFormData);
      setFormData (newFormData);
    } else {
      // Directly supplied a field and value.
      const newFormData = {...formData, ...update};
      console.log (newFormData);
      setFormData (newFormData);
    }
  };

  const onSubmitProduction = async () => {
    console.log ('Submitting production form:');
    console.log (formData);

    var db = firebase.firebase.firestore ();

    // Get the Hub ID associated with this user.
    const hubId = await auth.getUserHubId ();
    if (!hubId) {
      window.alert (`Error: Couldn't get your Hub's information from the database. Please contact support@ecosoapbank.org`);
      return;
    }

    // Push to Firebase
    db.collection ('hubs').doc (hubId).collection ('dailyProduction').add ({
      date: formData ['dp-form-date'],
      bars: parseInt (formData ['dp-form-bars']),
      soapmakers: parseInt (formData ['dp-form-soapmakers']),
      soapmakerHours: parseInt (formData ['dp-form-soapmaker-hours']),
    })
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);
      // onSetView ('confirmation');
      window.alert ('Submitted successfully.');
      onSetView ('selectForm');
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      window.alert ('An error occured. Please contact support@ecosoapbank.org for assistance.');
    });


    // Push to Google Sheet
    // TOOD

  };

  const onSubmitOrderPlaced = async () => {
    window.alert ('Submitting');
  };

  const onSubmitOrderCompleted = async () => {
    window.alert ('Submitting');
  };

  const views = {
    selectHub: <SelectHub onSelectHub={onSelectHub} />,
    selectForm: <SelectForm onSelectForm={onSelectForm} onSetView={onSetView} />,
    dailyProduction: <DailyProduction onSetView={onSetView} onChangeValue={onChangeValue} onSubmit={onSubmitProduction} />,
    orderPlaced: <OrderPlaced onSetView={onSetView} onChangeValue={onChangeValue} onSubmit={onSubmitOrderPlaced} />,
    orderCompleted: <OrderCompleted onSetView={onSetView} onChangeValue={onChangeValue} onSubmit={onSubmitOrderCompleted} />,
    confirmation: null, // TOOD
  }
  const activeView = views [view];


  return (

      <div className="fullscreen reporting-screen">

        <div className="reporting-title-card">
          <img src={logo} className="reporting-logo" alt="Eco-Soap Bank Logo" />
          <h1 className="reporting-h1">Hub Reporting</h1>
        </div>

        {activeView}

        <Button color="red" onClick={auth.signOut}>Log Out</Button>

      </div>

  );



  // <Input name="Email" required value={form.Email} onChange={onChangeValue}></Input>
  // <Input name="Password" required type="password" value={form.Password} onChange={onChangeValue}></Input>
  // <Button onClick={onSubmit}>Log In</Button>
  // <ShallowLink to="/forgot">Forgot?</ShallowLink>

}

export default Reporting;
