import React, { Component, useState, useEffect } from 'react';

import '../styles/App.css';
import './Button.css';

let icon_valid = require('../resources/icons/check-green.png');
let icon_error = require('../resources/icons/x-red.png');

// Props
// -----------------------------------------------------------
// color
// -----------------------------------------------------------


// TODO:
// -----------------------------------------------------------
// -
// -----------------------------------------------------------

const styles = {
  wrapper: {
    hover: {

    },
    focus: {

    },
    disabled: {
      backgroundColor: '#B2B2B2',
    },
    green: {
      backgroundColor: '#3bb54a',
    },
    blue: {
      backgroundColor: '#3B88FD',
    },
    gray: {
      backgroundColor: '#4D4D4D',
    },
    red: {
      backgroundColor: '#FF0000',
    }
  },
};

export default function Button (props) {

  const getWrapperStyle = () => {
    let wrapperStyle = {};
    if (props.color && styles.wrapper [props.color]) {
      wrapperStyle = { ...wrapperStyle, ...styles.wrapper [props.color]};
    }
    if (props.disabled) {
      wrapperStyle = { ...wrapperStyle, ...styles.wrapper.disabled };
    }
    return wrapperStyle;
  };

  const [ style, setStyle ] = useState ({ wrapper: getWrapperStyle () });

  useEffect (() => {
    setStyle ({ ...style, wrapper: getWrapperStyle () });
  }, [props.disabled, props.color]);

  const validate = () => {
    // TODO

    return true;
  };

  // const setButtonState = (buttonState, value = true) => {
  //   // Options: hover, focus, disabled, default
  //   switch (buttonState) {
  //     case ('hover'):
  //       this.setState ({ hover: value });
  //       return;
  //     case ('focus'):
  //       this.setState ({ focus: value });
  //       this.setStyle ({
  //         wrapper: value ? {...styles.wrapper.focus} : null,
  //       });
  //       return;
  //     case ('disabled'):
  //       this.setState ({ disabled: value });
  //       this.setStyle ({
  //         wrapper: {...styles.wrapper.disabled},
  //       });
  //       return;
  //     default:
  //       this.setState ({ icon: null });
  //       this.setStyle ({
  //         wrapper: null,
  //       });
  //   }
  // };

  // onMouseEnter = (e) => {
  //   this.setInputState ('hover');
  // };
  //
  // onMouseLeave = (e) => {
  //   this.setInputState ('hover', false);
  // };
  //
  // onFocus = (e) => {
  //   this.setInputState ('focus');
  // };
  //
  // onBlur = (e) => {
  //   this.setInputState ('focus', false);
  // };

  const onClick = (e) => {
    if (!props.disabled) {
      props.onClick (e);
    }
  }

  return (
    <div className="button-wrapper" style={style.wrapper} onClick={onClick}>
      <div className="button-text">
        {props.children}
      </div>
    </div>
  );

}
