import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, Auth } from '../../utils/auth';
import { DataContext, Actions } from '../../utils/data';
import '../../styles/App.css';
import '../Module.css';
import './ImpactSummary.css';

import Loader from '../../components/Loader';
import Button from '../../components/Button';
import ShallowLink from '../../components/ShallowLink';


export default function ImpactSummary (props) {

  const appState = useContext (DataContext);

  const [ loading, setLoading ] = useState (true);
  const [ activeProperty, setActiveProperty ] = useState (null);

  // Wait for data. The ActiveProperty module queries the DB and adds property
  // data to the App state.
  useEffect (() => {
    const state = appState.state;
    if (state.activeProperty) {
      setActiveProperty (state.activeProperty);
      setLoading (false);
    }
  }, [appState.state.activeProperty]);

  // Track loading in state because it's bidrectional
  // useEffect (() => {
  //   props.setLoading (loading);
  // }, [loading]);

  const getName = () => {
    let name = props.name;
    if (name) {
      return ( <h1>{name}</h1> );
    }
  };

  const getImpactStats = () => {
    if (!activeProperty || !activeProperty.impact) { return null; }

    let impact = activeProperty.impact;

    let stats = [];

    if (impact.soapRecycled) {
      stats.push ({
        header: "Soap Recycled:",
        value: impact.soapRecycled,
      });
    }

    if (impact.bottlesRecycled) {
      stats.push ({
        header: "Bottle Amenities Recycled:",
        value: impact.bottlesRecycled,
      });
    }

    if (impact.peopleServed) {
      stats.push ({
        header: "People Served:",
        value: impact.peopleServed,
      });
    }

    if (impact.womenEmployed) {
      stats.push ({
        header: "Women Employed:",
        value: impact.womenEmployed,
      });
    }

    return (
      <div className="impact-summary-table">
        {
          stats.map (stat => {
            return (
              <div key={stat.header} className="impact-summary-table-row">
                <p className="impact-summary-header">{stat.header}</p>
                <p className="impact-summary-stat">{stat.value}</p>
              </div>
            );
          })
        }
      </div>
    );
  };

  const getImpactMedia = () => {
    if (!activeProperty || !activeProperty.mediaThumb) { return null; }
    return ( <img src="https://picsum.photos/400/300" alt={activeProperty.name} />) // TODO: Change image src to download from Firebase Storage
  };

  let color = props.color ||'#4D4D4D';
  let style = props.style;

  return (
    <div className="module" style={style}>
      <div className="module-color-stub" style={{ backgroundColor: color }}></div>
      <div className={loading ? "module-loader-loading" : "module-loader"}>
        <Loader />
      </div>
      <div className={loading ? "module-inner-loading" : "module-inner"}>
        <div className="impact-summary">
          <div className="impact-summary-stats">
            {getName ()}
            {getImpactStats ()}
          </div>
          <div className="impact-summary-media">
            {getImpactMedia ()}
          </div>
        </div>
      </div>
    </div>
  );

}
